import React, { useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { ContextMenu, MenuItem } from "react-contextmenu";

const RightClick = ({
    ContextId,
    handleClick1,
    handleClick2,
    handleClick3,
    handleClick4,
    handleClick5,
    handleClick6,
    handleClick7,
    handleClick8,
    handleClick9,
    handleClick10,
    handleClick12,
    handleClick13,
    handleClick15,
    handleClick16,
    handleClick17,
    handleClick18,
    handleClick19,
    handleClick20,
    handleClick21,
    icons1,
    icons2,
    icons3,
    icons4,
    icons5,
    icons6,
    icons7,
    icons8,
    icons9,
    icons10,
    icons11,
    icons12,
    icons13,
    icons14,
    icons15,
    icons16,
    icons17,
    icons18,
    icons19,
    icons20,
    icons21,
    MenuName1,
    MenuName2,
    MenuName3,
    MenuName4,
    MenuName5,
    MenuName6,
    MenuName7,
    MenuName8,
    MenuName9,
    MenuName10,
    MenuName20,
    MenuName21,
    Menu1,
    Menu2,
    Menu3,
    Menu4,
    Menu5,
    Menu6,
    Menu7,
    Menu8,
    Menu9,
    Menu10,
    Menu11,
    Menu12,
    Menu20,
    Menu21,
    DropdownTitle11,
    DropdownTitle12,
    DropdownItemSubMenu1,
    DropdownItemSubMenu2,
    DropdownItemSubMenu3,
    DropdownItemSubMenu4,
    DropdownItemSubMenu5,
    DropdownItemSubMenu6,
    DropdownItemSubMenu7,
    className1,
    className2,
    className3,
    className4,
    className5,
    className6,
    className7,
    className8,
    className9,
    className10,
    className11,
    className12,
    className13,
    className15,
    className16,
    className17,
    className18,
    className19,
    className20,
    className21,
    DropdownMenu1,
    DropdownMenu2,
    DropdownMenu3,
    DropdownMenu4,
    DropdownMenu5,
    DropdownMenu6,
    DropdownMenu7
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const [showDropdown1, setShowDropdown1] = useState(false);

  const handleMouseEnter1 = () => {
    setShowDropdown1(true);
  };

    const handleMouseLeave1 = () => {
        setShowDropdown1(false);
    };
    return (
        <>
            <ContextMenu id={ContextId}>
                {Menu1 &&
                    <MenuItem onClick={handleClick1}>
                        {icons1}
                        <span className={className1}>{MenuName1}</span>
                    </MenuItem>
                }
                {Menu2 &&
                    <MenuItem onClick={handleClick2}>
                        {icons2}
                        <span className={className2}>{MenuName2}</span>
                    </MenuItem>
                }
                {Menu20 &&
                    <MenuItem onClick={handleClick20}>
                        {icons20}
                        <span className={className20}>{MenuName20}</span>
                    </MenuItem>
                }
                {Menu21 &&
                    <MenuItem onClick={handleClick21}>
                        {icons21}
                        <span className={className21}>{MenuName21}</span>
                    </MenuItem>
                }
                {Menu11 &&
                    <MenuItem >
                        {icons11}
                        {['end'].map((direction) => (
                            <div
                                key={direction}
                                id={`dropdown-button-drop-${direction}`}
                                className="d-inline-block"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <DropdownButton
                                    as={ButtonGroup}
                                    drop={direction}
                                    title={DropdownTitle11}
                                    className={className11}
                                    show={showDropdown}
                                >
                                    {DropdownMenu1 &&
                                        <Dropdown.Item onClick={handleClick12} as="button">{icons12}<span className={`${className12} mx-2`}>{DropdownItemSubMenu1}</span></Dropdown.Item>
                                    }
                                    {DropdownMenu2 &&
                                        <Dropdown.Item onClick={handleClick13} as="button">{icons13}<span className={`${className13} mx-2`}>{DropdownItemSubMenu2}</span></Dropdown.Item>
                                    }
                                </DropdownButton>
                            </div>
                        ))}
                    </MenuItem>
                }

                {Menu12 &&
                    <MenuItem>
                        {icons14}
                        {['end'].map((direction) => (
                            <div
                                key={direction}
                                id={`dropdown-button-drop-${direction}`}
                                className="d-inline-block"
                                onMouseEnter={handleMouseEnter1}
                                onMouseLeave={handleMouseLeave1}
                            >
                                <DropdownButton
                                    as={ButtonGroup}
                                    drop={direction}
                                    title={DropdownTitle12}
                                    show={showDropdown1}
                                >
                                    {DropdownMenu3 &&
                                        <Dropdown.Item onClick={handleClick15} as="button">{icons15}<span className={`${className15} mx-2`}>{DropdownItemSubMenu3}</span></Dropdown.Item>
                                    }
                                    {DropdownMenu4 &&
                                        <Dropdown.Item onClick={handleClick16} as="button">{icons16}<span className={`${className16} mx-2`}>{DropdownItemSubMenu4}</span></Dropdown.Item>
                                    }
                                    {DropdownMenu5 &&
                                        <Dropdown.Item onClick={handleClick17} as="button">{icons17}<span className={`${className17} mx-2`}>{DropdownItemSubMenu5}</span></Dropdown.Item>
                                    }
                                    {DropdownMenu6 &&
                                        <Dropdown.Item onClick={handleClick18} as="button">{icons18}<span className={`${className18} mx-2`}>{DropdownItemSubMenu6}</span></Dropdown.Item>
                                    }
                                    {DropdownMenu7 &&
                                        <Dropdown.Item onClick={handleClick19} as="button">{icons19}<span className={`${className19} mx-2`}>{DropdownItemSubMenu7}</span></Dropdown.Item>
                                    }
                                </DropdownButton>
                            </div>
                        ))}
                    </MenuItem>
                }

                {Menu3 &&
                    <MenuItem onClick={handleClick3}>
                        {icons3}
                        <span className={className3}>{MenuName3}</span>
                    </MenuItem>
                }
                {Menu4 &&
                    <MenuItem onClick={handleClick4}>
                        {icons4}
                        <span className={className4}>{MenuName4}</span>
                    </MenuItem>
                }
                {Menu5 &&
                    <MenuItem onClick={handleClick5}>
                        {icons5}
                        <span className={className5}>{MenuName5}</span>
                    </MenuItem>
                }
                {Menu6 &&
                    <MenuItem onClick={handleClick6}>
                        {icons6}
                        <span className={className6}>{MenuName6}</span>
                    </MenuItem>
                }
                {Menu7 &&
                    <MenuItem onClick={handleClick7}>
                        {icons7}
                        <span className={className7}>{MenuName7}</span>
                    </MenuItem>
                }
                {Menu8 &&
                    <MenuItem onClick={handleClick8}>
                        {icons8}
                        <span className={className8}>{MenuName8}</span>
                    </MenuItem>
                }
                {Menu9 &&
                    <MenuItem onClick={handleClick9}>
                        {icons9}
                        <span className={className9}>{MenuName9}</span>
                    </MenuItem>
                }
                {Menu10 &&
                    <MenuItem onClick={handleClick10}>
                        {icons10}
                        <span className={className10}>{MenuName10}</span>
                    </MenuItem>
                }

            </ContextMenu>
        </>
    )
};
export default RightClick;
