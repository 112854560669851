import React, { useEffect, useRef, useState } from "react";
import { HiMiniChevronDown } from "react-icons/hi2";
import { TiHomeOutline } from "react-icons/ti";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import Swal from "sweetalert2";
import DraggableTab from "./DraggableTab/DraggableTab.js";
import DnDContext from "./DraggableTab/DnDContext";
import TabHistory from "./DraggableTab/TabHistory.js";

const EDCMainPanel = ({ setEDCTabs, edcTabs, activeedcTab }) => {
  const [open, setOpen] = useState(false);
  const toggleRef = useRef(null);
  const tabContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(edcTabs?.length > 1);
  const handleActiveTab = (edcTabId) => {
    const updatedEDCTabs = edcTabs?.map((edcTab) => ({
      ...edcTab,
      status: "inactive",
    }));
    updatedEDCTabs.forEach((element) => {
      if (element.edcTabId === edcTabId) {
        element.status = "active";
      }
    });
    setEDCTabs(updatedEDCTabs);
  };

  const handleInactiveAllTab = () => {
    const updatedEDCTabs = edcTabs?.map((edcTab) => ({
      ...edcTab,
      status: "inactive",
    }));
    setEDCTabs(updatedEDCTabs);
  };

  const handleDeleteTab = (edcTabId) => {
    const updatedEDCTabs = edcTabs?.filter(
      (edcTab) => edcTab.edcTabId !== edcTabId
    );
    setEDCTabs(updatedEDCTabs);
  };

  const handleDeleteAllTab = () => {
    Swal.fire({
      title: "You want to Close all Tab",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setEDCTabs([]);
      }
    });
  };

  const handleCloseOtherTab = (item) => {
    Swal.fire({
      title: `You want to Close other Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let updatedEDCTabs = { ...item, status: "active" };
        setEDCTabs([updatedEDCTabs]);
      }
    });
  };

  const handleCloseRightTabs = (index) => {
    Swal.fire({
      title: `You want to Close all Right Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedEDCTabs = edcTabs?.slice(0, index + 1);
        const deletedArray = edcTabs?.slice(index + 1);
        const checkStatus = deletedArray?.some(
          (item) => item.status === "active"
        );
        if (checkStatus) {
          updatedEDCTabs[updatedEDCTabs?.length - 1].status = "active";
          setEDCTabs(updatedEDCTabs);
        } else {
          setEDCTabs(updatedEDCTabs);
        }
      }
    });
  };

  const handleCloseLeftTabs = (index) => {
    Swal.fire({
      title: `You want to Close all left Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedEDCTabs = edcTabs?.slice(index);
        const deletedArray = edcTabs?.slice(0, index);
        const checkStatus = deletedArray?.some(
          (item) => item.status === "active"
        );
        if (checkStatus) {
          updatedEDCTabs[0].status = "active";
          setEDCTabs(updatedEDCTabs);
        } else {
          setEDCTabs(updatedEDCTabs);
        }
      }
    });
  };

  const handleMoveTab = (fromIndex, toIndex) => {
    setEDCTabs((prevTabs) => {
      const updatedEDCTabs = [...prevTabs];
      const [movedTab] = updatedEDCTabs.splice(fromIndex, 1);
      updatedEDCTabs.splice(toIndex, 0, movedTab);
      return updatedEDCTabs;
    });
  };

  const handleMoveTabLeft = (index) => {
    if (index > 0) {
      handleMoveTab(index, index - 1);
    }
  };

  const handleMoveTabRight = (index) => {
    if (index < edcTabs?.length - 1) {
      handleMoveTab(index, index + 1);
    }
  };

  const handleScrollTabLeft = () => {
    tabContainerRef?.current?.scrollBy({ left: -150, behavior: "smooth" });
    updateScrollState();
  };

  const handleScrollTabRight = () => {
    tabContainerRef?.current?.scrollBy({ left: 150, behavior: "smooth" });
    updateScrollState();
  };

  const updateScrollState = () => {
    const scrollLeft = tabContainerRef?.current?.scrollLeft;
    const scrollWidth = tabContainerRef?.current?.scrollWidth;
    const clientWidth = tabContainerRef?.current?.clientWidth;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef?.current && !toggleRef?.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div
        ref={toggleRef}
        className="border-bottom tabbars hstack gap-2 px-2 pb-0 bg-light position-relative"
        style={{ padding: 5 }}
      >
        {edcTabs?.length > 0 && (
          <HiMiniChevronDown
            onClick={() => setOpen(!open)}
            className={open && "activee"}
          />
        )}
        <TabHistory
          open={open}
          edcTabs={edcTabs}
          handleMoveTab={handleMoveTab}
          handleActiveTab={handleActiveTab}
          handleDeleteTab={handleDeleteTab}
          handleDeleteAllTab={handleDeleteAllTab}
        />
        <TiHomeOutline
          className={!activeedcTab && "activee"}
          onClick={handleInactiveAllTab}
        />
        {edcTabs?.length >= 8 && (
          <MdOutlineKeyboardArrowLeft
            className={canScrollLeft && "activee"}
            onClick={handleScrollTabLeft}
            title="← Left Scroll"
          />
        )}
        <DnDContext>
          <div
            ref={tabContainerRef}
            onScroll={updateScrollState}
            className="hstack gap-2 w-100 overflow-auto blank"
          >
            {edcTabs?.map((item, index) => (
              <DraggableTab
                key={item.edcTabId}
                item={item}
                index={index}
                handleMoveTab={handleMoveTab}
                handleActiveTab={handleActiveTab}
                handleDeleteTab={handleDeleteTab}
                handleCloseOtherTab={handleCloseOtherTab}
                handleCloseRightTabs={handleCloseRightTabs}
                handleCloseLeftTabs={handleCloseLeftTabs}
                handleDeleteAllTab={handleDeleteAllTab}
                handleMoveTabLeft={handleMoveTabLeft}
                handleMoveTabRight={handleMoveTabRight}
              />
            ))}
          </div>
        </DnDContext>
        {edcTabs?.length >= 8 && (
          <MdOutlineKeyboardArrowRight
            className={canScrollRight && "activee"}
            onClick={handleScrollTabRight}
            title="Right Scroll →"
          />
        )}
      </div>
    </>
  );
};

export default EDCMainPanel;
