import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import Pagination from "../../../Pagination/Pagination";

const ClientsDataListingTable = ({
  clientLists,
  clientDetail,
  updateClientServerStatus,
  itemsPerPage,
  setPageNumber,
  setItemsPerPage,
  totalPage,
  totalData,
  pageNumber,
  access,
  setViewStudyDetailsVisible,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);

  const getIDData = async (_id) => {
    clientDetail(_id);
  };

  return (
    <>
      <Table id="resizable-table" striped hover className="m-0 custom-table">
        <thead className="position-sticky top-0 z-1">
          <tr>
            <th style={{ width: 50 }}>S No.</th>
            <th>ClientID</th>
            <th>Client Name</th>
            <th>Server Status</th>
          </tr>
        </thead>

        <tbody className="border-top-0">
          {clientLists?.map(({ _id, userId, firstName, serverStatus }, key) => (
            <React.Fragment>
              <tr
                key={key}
                className={`CP ${highlightedRow === key && "highlighted"}`}
                title="Click to View Details"
              >
                <td
                  onClick={() => {
                    setViewStudyDetailsVisible(true);
                    getIDData(_id, userId);
                    setHighlightedRow(key);
                  }}
                >
                  {key + 1}
                </td>
                <td
                  onClick={() => {
                    setViewStudyDetailsVisible(true);
                    getIDData(_id, userId);
                    setHighlightedRow(key);
                  }}
                >
                  {userId}
                </td>
                <td
                  className="textCapitialize"
                  onClick={() => {
                    setViewStudyDetailsVisible(true);
                    getIDData(_id, userId);
                    setHighlightedRow(key);
                  }}
                >
                  {firstName}
                </td>
                {access === "activeClients" ||
                access === "onBoardingClients" ? (
                  <td className="textCapitialize p-0">
                    <Form.Select
                      className="form-select countryoption w-100 border-0 rounded-0 fontSize12"
                      style={{
                        color:
                          serverStatus === "completed"
                            ? "green"
                            : serverStatus === "pending"
                            ? "grey"
                            : "black",
                      }}
                      onChange={(e) => updateClientServerStatus(e, _id)}
                    >
                      <option
                        selected={serverStatus === "completed"}
                        value="completed"
                      >
                        Completed
                      </option>
                      <option
                        selected={serverStatus === "pending"}
                        value="pending"
                      >
                        Pending
                      </option>
                    </Form.Select>
                  </td>
                ) : (
                  <td className="textCapitialize">{serverStatus}</td>
                )}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={totalData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default ClientsDataListingTable;
