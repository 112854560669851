import React from "react";
import { FaShield } from "react-icons/fa6";

const RBQMControl = ({ handleAddNewTab, checkActiveTab }) => {
  return (
    <>
      <span
        className={`CP ps-3 small py-1 ${
          checkActiveTab("riskCategoryDataId") && "selectedMenu"
        }`}
        onClick={() => {
          handleAddNewTab(
            `Risk Category`,
            "fa-solid fa-shield",
            "riskCategoryDataId"
          );
        }}
      >
        <FaShield className="ps-1 me-2" style={{ fontSize: 16 }} />
        Risk Category
      </span>
      <span
        className={`CP ps-3 small py-1 ${
          checkActiveTab("riskAreaDataId") && "selectedMenu"
        }`}
        onClick={() => {
          handleAddNewTab(`Risk Area`, "fa-solid fa-shield", "riskAreaDataId");
        }}
      >
        <FaShield className="ps-1 me-2" style={{ fontSize: 16 }} />
        Risk Area
      </span>
      <span
        className={`CP ps-3 small py-1 ${
          checkActiveTab("riskManagementDataId") && "selectedMenu"
        }`}
        onClick={() => {
          handleAddNewTab(
            `Risk Management`,
            "fa-solid fa-shield",
            "riskManagementDataId"
          );
        }}
      >
        <FaShield className="ps-1 me-2" style={{ fontSize: 16 }} />
        Risk Management
      </span>
    </>
  );
};

export default RBQMControl;
