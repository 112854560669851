import React, { useState } from "react";
import "../../../Styles/Login.scss";
import Navbar from "../../../Components/Navbar/Navbar";
import "./UserProfile.scss";
import Footer from "../../../Components/Footer/Footer";
import { userServices } from "../../../Services/userServices";
import UseToggle from "../../../Hooks/UseToggle";
import EyeIcon, { EyeSlashIcon } from "../../../Assets/Svg/EyeIcon";
import { Card, Form} from "react-bootstrap";

const ChangePassword = () => {
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    togglePassword,
  } = UseToggle();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const updatePassword = JSON.parse(localStorage.getItem("updatePassword"));

  const handleSubmit = async (e) => {
    e.preventDefault();

    let userData = {};
    userData.currentPassword = oldPassword;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;

    await userServices.changePassword(userData);
  };

  const [error, setError] = useState('');

  const validatePassword = (input) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    if (!passwordRegex.test(input)) {
      setError("Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long.");
    } else {
      setError('');
    }
  };

  return (
    <>
      <Navbar Roles="User" />
      <div
        className="hstack justify-content-center overflow-auto"
        style={{ height: "calc(100vh - 114px)" }}
      >
        <Card style={{ width: "35rem" }} className="p-3">
          <Card.Body>
            <Card.Title className="text-center">Change Password</Card.Title>
            {!updatePassword && (
              <center className="mb-2">
                <Form.Label>
                  Please First Update Password, After then You can Access All
                  Features !
                </Form.Label>
              </center>
            )}
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                Current Password <span className="text-danger">*</span>
              </Form.Label>
              <div className="position-relative passwordSection">
                <Form.Control
                  type={togglePassword(toggle)}
                  placeholder="Enter your Current Password"
                  onChange={(e) => setOldPassword(e.target.value)}
                  value={oldPassword}
                />
                <span className="passwordIcon CP" onClick={setToggle}>
                  {toggle ? <EyeIcon /> : <EyeSlashIcon />}
                </span>
              </div>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                New Password <span className="text-danger">*</span>
              </Form.Label>
              <div className="position-relative passwordSection">
                <Form.Control
                  type={togglePassword(toggle1)}
                  placeholder="Enter your New Password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                  value={newPassword}
                />
                <span className="passwordIcon CP" onClick={setToggle1}>
                  {toggle1 ? <EyeIcon /> : <EyeSlashIcon />}
                </span>
              </div>
              {error && (
                <p className="text-danger m-0" style={{ fontSize: "11px" }}>
                  {error}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 fw-bold">
                Confirm Password <span className="text-danger">*</span>
              </Form.Label>
              <div className="position-relative passwordSection">
                <Form.Control
                  type={togglePassword(toggle2)}
                  placeholder="Enter your Confirm Password"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  value={repeatPassword}
                />
                <span className="passwordIcon CP" onClick={setToggle2}>
                  {toggle2 ? <EyeIcon /> : <EyeSlashIcon />}
                </span>
              </div>
              {newPassword === repeatPassword ? (
                ""
              ) : (
                <p className="text-danger m-0" style={{ fontSize: "11px" }}>
                  {"Repeat Password not match from new password"}
                </p>
              )}
            </Form.Group>
            <Form.Group>
              <button
                disabled={
                  error ||
                  newPassword !== repeatPassword ||
                  newPassword?.length === 0 ||
                  oldPassword?.length === 0
                }
                className="updateProfile py-2 w-100"
                onClick={(e) => handleSubmit(e)}
              >
                Change Password
              </button>
            </Form.Group>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default ChangePassword;
