import { createContext, useContext, useState } from "react";

export const StudySubjectContext = createContext();

export const StudySubjectContextProvider = ({ children }) => {
  const [studySubjectState, setStudySubjectState] = useState([]);
  const [studySubjectIdOfVisitsState, setStudySubjectIdOfVisitsState] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [selectedStudySiteId, setSelectedStudySiteId] = useState("");
  const [selectedStudySubjectId, setSelectedStudySubjectId] = useState("");
  const [selectedStudyVisitsId, setSelectedStudyVisitsId] = useState("");
  const [selectedStudyVisitsInitiateId, setSelectedStudyVisitsInitiateId] = useState("");
  const [selectedStudyVisitsForm, setSelectedStudyVisitsForm] = useState("");
  const [selectedStudyEventsId, setSelectedStudyEventsId] = useState("");
  const [subjectUniqueId, setSubjectUniqueId] = useState("");
  const [visitUniqueId, setVisitUniqueId] = useState("");
  const [formName, setFormName] = useState("");

  const contextValue = {
    formName,
    setFormName,
    visitUniqueId,
    setVisitUniqueId,
    subjectUniqueId,
    setSubjectUniqueId,
    studySubjectState,
    setStudySubjectState,
    studySubjectIdOfVisitsState,
    setStudySubjectIdOfVisitsState,
    setProjectId,
    projectId,
    setSelectedStudyVisitsId,
    selectedStudyVisitsId,
    setSelectedStudySubjectId,
    selectedStudySubjectId,
    setSelectedStudySiteId,
    selectedStudySiteId,
    setSelectedStudyVisitsInitiateId,
    selectedStudyVisitsInitiateId,
    setSelectedStudyVisitsForm,
    selectedStudyVisitsForm,
    setSelectedStudyEventsId,
    selectedStudyEventsId
  };

  return (
    <StudySubjectContext.Provider value={contextValue}>
      {children}
    </StudySubjectContext.Provider>
  );
};

export const UseStudySubjectContext = () => useContext(StudySubjectContext);
