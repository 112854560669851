import React, { useContext, useEffect, useState } from "react";
import LeftComponent from "../../../Components/LeftComponent/LeftComponent11";
import { usersOnboardingService } from "../../../Services/usersOnboarding";
import Navbar from "../../../Components/Navbar/Navbar";
import { ResizablexContext } from "../../../Components/CommonComponents/ResizeTable/ResizeTable";
import Footer from "../../../Components/Footer/Footer";
import UseAdminStates from "../../../Hooks/UseAdminStates";
import UseSidebarReducer from "../../../Hooks/UseSidebarReducer";
import ResizerXManager from "../../../Components/ResizerXManager/ResizerXManager";
import {
  ResizableX,
  ResizableY,
  SidebarChild,
} from "../../../Components/Layouts/Layouts";
import { useParams } from "react-router";
import DevopsPage from "./DevopsPage";

const Devops = ({ tableRef, onMouseDown }) => {
  const resizableX = useContext(ResizablexContext);
  const { showSidebar } = UseSidebarReducer();

  const {
    activeClients,
    setActiveClients,
    inactiveClients,
    setInactiveClients,
    freezedClients,
    setFreezedClients,
    onBoardingClients,
    setOnBoardingClients,
    archivedClients,
    setArchivedClients,
    approvedClients,
    setApprovedClients,
    requestPendingClients,
    setRequestPendingClients,
    requestRejectedClients,
    setRequestRejectedClients,
    requestApprovedClients,
    setRequestApprovedClients,
    archivePendingClients,
    setArchivePendingClients,
    archiveRejectedClients,
    setArchiveRejectedClients,
    archiveApprovedClients,
    setArchiveApprovedClients,
  } = UseAdminStates();

  const childResponce = () => {
    countOfArchivedRequest();
  };

  const totalRequestCount = () => {
    countOfArchivedRequest();
    countOfRequestWithinDays();
    countOfClientInvitation();
    countOfUserInvitation();
  };

  const permission = JSON.parse(localStorage.getItem("permission"));
  const [globalFormFolderID, setGlobalFormFolderID] = useState("");
  const [folderName, setFolderName] = useState("");
  const [modalId, setModalId] = useState("");
  const [modalName, setModalName] = useState("");

  const [approvedClientInvitation, setApprovedClientInvitation] = useState("");
  const [pendingClientInvitation, setPendingClientInvitation] = useState("");
  const [rejectedClientInvitation, setRejecteddClientInvitation] = useState("");
  const [approvedUserInvitation, setApprovedUserInvitation] = useState("");
  const [pendingUserInvitation, setPendingUserInvitation] = useState("");
  const [rejectedUserInvitation, setRejecteddUserInvitation] = useState("");

  const [activeDevops, setActiveDevops] = useState("");
  const [inactiveDevops, setInactiveDevops] = useState("");
  const [archiveDevops, setArchiveDevops] = useState("");
  const [freezeDevops, setFreezeDevops] = useState("");
  const [onBoardDevops, setOnBoardDevopsn] = useState("");
  const [edcTabs, setEDCTabs] = useState([]);
  const { edcId } = useParams();
  const countOfClientInvitation = async (e) => {
    let data = await usersOnboardingService.countClientUserInvitation("client");
    setApprovedClientInvitation(data.approvedInvitation);
    setPendingClientInvitation(data.pendingInvitation);
    setRejecteddClientInvitation(data.rejectedInvitation);
  };

  const countOfUserInvitation = async (e) => {
    let data = await usersOnboardingService.countClientUserInvitation("user");
    setApprovedUserInvitation(data.approvedInvitation);
    setPendingUserInvitation(data.pendingInvitation);
    setRejecteddUserInvitation(data.rejectedInvitation);
  };

  const countOfRoleStatus = async (e) => {
    let count = await usersOnboardingService.getSeparateRoleStatusCount(
      permission
    );
    let data = count.RoleStatus[0];
    setActiveClients(data.totalActiveClients > 0 ? data.totalActiveClients : 0);
    setFreezedClients(
      data.totalFreezeClients > 0 ? data.totalFreezeClients : 0
    );
    setOnBoardingClients(
      data.totalOnBoardingClients > 0 ? data.totalOnBoardingClients : 0
    );
    setInactiveClients(
      data.totalInactiveClients > 0 ? data.totalInactiveClients : 0
    );
    setArchivedClients(
      data.totalArchiveClients > 0 ? data.totalArchiveClients : 0
    );
    setApprovedClients(
      data.totalApprovedClients > 0 ? data.totalApprovedClients : 0
    );
    setActiveDevops(data.totalActiveDevops > 0 ? data.totalActiveDevops : 0);
    setInactiveDevops(
      data.totalInactiveDevops > 0 ? data.totalInactiveDevops : 0
    );
    setArchiveDevops(data.totalArchiveDevops > 0 ? data.totalArchiveDevops : 0);
    setFreezeDevops(data.totalFreezeDevops > 0 ? data.totalFreezeDevops : 0);
    setOnBoardDevopsn(
      data.totalOnBoardingDevops > 0 ? data.totalOnBoardingDevops : 0
    );
  };

  const countOfArchivedRequest = async (e) => {
    let count = await usersOnboardingService.countOfArchivedRequest(e);
    let data = count.RoleStatus[0];
    setArchivePendingClients(
      data.totalPendingClients > 0 ? data.totalPendingClients : 0
    );
    setArchiveApprovedClients(
      data.totalApprovedClients > 0 ? data.totalApprovedClients : 0
    );
    setArchiveRejectedClients(
      data.totalRejectedClients > 0 ? data.totalRejectedClients : 0
    );
  };

  const countOfRequestWithinDays = async (e) => {
    let count = await usersOnboardingService.countOfRequestWithinDays(e);
    let data = count.RoleStatus[0];
    setRequestPendingClients(
      data.totalPendingClients > 0 ? data.totalPendingClients : 0
    );
    setRequestApprovedClients(
      data.totalApprovedClients > 0 ? data.totalApprovedClients : 0
    );
    setRequestRejectedClients(
      data.totalRejectedClients > 0 ? data.totalRejectedClients : 0
    );
  };

  const stateChangerFormID = (id, name) => {
    setGlobalFormFolderID(id);
    setFolderName(name);
  };

  useEffect(() => {
    resizableX();
  });

  const handleAddNewTab = (edcTabName, icon, edcTabId) => {
    let newTab = {
      edcTabId: edcTabId,
      edcTabName: edcTabName,
      icon: icon,
      openDT: new Date(),
      status: "active",
    };
    const updatedTabs = edcTabs?.map((edcTab) => ({
      ...edcTab,
      status: "inactive",
    }));
    const existTab = edcTabs?.some((edcTab) => edcTab.edcTabId === edcTabId);
    if (existTab) {
      updatedTabs.forEach((element) => {
        if (element.edcTabId === edcTabId) {
          element.status = "active";
          element.edcTabName = edcTabName;
        }
      });
      setEDCTabs(updatedTabs || []);
    } else {
      setEDCTabs([...updatedTabs, newTab] || []);
    }
  };

  const checkActiveTab = (edcTabId) => {
    return edcTabs?.some(
      (item) => item.edcTabId === edcTabId && item.status === "active"
    );
  };

  const checkHomeActiveTab = () => {
    return edcTabs?.some((item) => item.status === "active");
  };

  const activeEDCTab = edcTabs?.some((item) => item.status === "active");

  useEffect(() => {
    const savededcTabs = JSON.parse(localStorage.getItem("edcTabs"));
    if (savededcTabs) {
      setEDCTabs(savededcTabs || []);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("edcTabs", JSON.stringify(edcTabs));
  }, [edcTabs]);

  return (
    <>
      <Navbar RemoveDropdowns Dropdown1="Clients" Dropdown5="Logs" />

      <ResizableX>
        {showSidebar && (
          <SidebarChild>
            <LeftComponent
              handleAddNewTab={handleAddNewTab}
              checkActiveTab={checkActiveTab}
              edcId={edcId}
              roleType={"Devops"}
              stateChangerFormID={stateChangerFormID}
              UsersDrop
              ClientsDrop
              RequestsDrop
              ActiveClientPermission
              ActiveClients={activeClients}
              InactiveClients={inactiveClients}
              FreezedClients={freezedClients}
              OnBoardingClients={onBoardingClients}
              ArchivedClients={archivedClients}
              ApprovedClients={approvedClients}
              RequestPendingClients={requestPendingClients}
              RequestRejectedClients={requestRejectedClients}
              RequestApprovedClients={requestApprovedClients}
              ArchivePendingClients={archivePendingClients}
              ArchiveRejectedClients={archiveRejectedClients}
              ArchiveApprovedClients={archiveApprovedClients}
              CountOfRoleStatus={countOfRoleStatus}
              ChildResponce={childResponce}
              TotalRequestCount={totalRequestCount}
              CountOfRequestWithinDays={countOfRequestWithinDays}
              CountOfArchivedRequest={countOfArchivedRequest}
              countOfClientInvitation={countOfClientInvitation}
              approvedClientInvitation={approvedClientInvitation}
              pendingClientInvitation={pendingClientInvitation}
              rejectedClientInvitation={rejectedClientInvitation}
              countOfUserInvitation={countOfUserInvitation}
              approvedUserInvitation={approvedUserInvitation}
              pendingUserInvitation={pendingUserInvitation}
              rejectedUserInvitation={rejectedUserInvitation}
              activeDevops={activeDevops}
              inactiveDevops={inactiveDevops}
              archiveDevops={archiveDevops}
              freezeDevops={freezeDevops}
              onBoardDevops={onBoardDevops}
              setModalId={setModalId}
              setModalName={setModalName}
              modalId={modalId}
            />
          </SidebarChild>
        )}

        <ResizerXManager />

        <ResizableY>
          <DevopsPage
            checkHomeActiveTab={checkHomeActiveTab}
            edcTabs={edcTabs}
            checkActiveTab={checkActiveTab}
            setEDCTabs={setEDCTabs}
            activeEDCTab={activeEDCTab}
            globalFormFolderID={globalFormFolderID}
            folderName={folderName}
            modalId={modalId}
            modalName={modalName}
            onMouseDown={onMouseDown}
            tableRef={tableRef}
          />
        </ResizableY>
      </ResizableX>

      <Footer />
    </>
  );
};

export default Devops;
