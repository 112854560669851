import config from "../config/config.json";
import saveAs from "file-saver";
import { headers } from "../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../Helpers/Responses";

export const clientsOnboardingService = {
  getClientData,
  searchUnAssignClients,
  assignedClientofUser,
  unAssignedClientofUser,
  getClientDataByID,
  createClientBySuperAdmin,
  createClientByUser,
  updateClientStatus,
  updateClientServerStatus,
  superUserEmail,
  updateUsersClientsByID,
  getArchivedRequestedClients,
  getRequestedClients,
  getAllClients,
  getActiveApprovedClients,
  getActiveApprovedClientsForValidator,
  getSingleClientForDevops,
  getActiveApprovedClientsForUser,
  updateClientStatusOnBoardingToActive,
  exportClientDataInCSVFormat,
  exportClientDataInExcelFormat,
  exportActiveClientDataInCSVFormat,
  exportActiveClientDataInExcelFormat,
  exportRequestClientDataInCSVFormat,
  exportRequestClientDataInExcelFormat,
  exportArchiveRequestClientDataInCSVFormat,
  exportArchiveRequestClientDataInExcelFormat,
};

async function getClientData(status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getClientsByStatus/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getArchivedRequestedClients(status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getArchivedRequestClients/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getRequestedClients(status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRequestClients/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function superUserEmail() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}get/superUserEmail`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getActiveApprovedClients() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveApprovedClients`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getAllClients() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAllClients`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getActiveApprovedClientsForValidator() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveApprovedClientsForValidator`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getActiveApprovedClientsForUser() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveApprovedClientsForUser`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getClientDataByID(clientID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}client/${clientID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getSingleClientForDevops(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSingleClientForDevops/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateUsersClientsByID(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/updateUsersClients`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  let getResponse = await handleResponse(data);
  if (getResponse === true) {
    history(`/adminRole`);
  }
}

async function createClientBySuperAdmin(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}clientRegistration`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let getResponse = await handleResponse(data);
  if (getResponse === true) {
    history(`/adminRole`);
  }
}

async function assignedClientofUser(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignedClientofUser`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function unAssignedClientofUser(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}unAssignedClientofUser`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function createClientByUser(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}request/clientRegistration`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let getResponse = await handleResponse(data);
  if (getResponse === true) {
    history(`/newclientcreation`);
  }
  return data;
}

async function updateClientStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}client/updateStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateClientServerStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}client/updateClientServerStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateClientStatusOnBoardingToActive(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}client/updateClientStatusOnBoardingToActive`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function searchUnAssignClients(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}searchUnAssignClients`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function exportClientDataInCSVFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportAllUserAndClientDataCSV/client/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportClientDataInExcelFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportAllUserAndClientDataEXCEL/client/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportActiveClientDataInCSVFormat() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportAllActiveApprovedUserAndClientInCSV/client`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportActiveClientDataInExcelFormat() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportAllActiveApprovedUserAndClientInEXCEL/client`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportRequestClientDataInCSVFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportRequestUserAndClientDataCSV/client/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportRequestClientDataInExcelFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportRequestUserAndClientDataExcel/client/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportArchiveRequestClientDataInCSVFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportArchiveRequestUserAndClientDataCSV/client/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportArchiveRequestClientDataInExcelFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportArchiveRequestUserAndClientDataExcel/client/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
