import config from "../../config/config.json";
// import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const StudyVisitFormServices = {
  sendForReviewStudyVisitForm,
  getStudyVisitsFormAttributeData,
  getStudyVisitsFormStatusAttributeData,
  deleteStudyVisitFormsData,
  updateStudyVisitFormsMeta,
  getStudyVisitsFormData,
  getStudyFormValidationData,
  duplicateStudyVisitFormsMeta,
  duplicateAssignStudyVisitFormsMeta,

  getQuerySummeryDataForAllForm,
  getManageQueryStatusDataForAllFormField,
  viewFormFieldAttributeBasedonFormFieldId,
  veiwTicketQueryReplyComments,

  getFormSDVData,
  getLogFormSDVData,
  getSpecialFormSDVData,
  getSpecialLogFormSDVData,
  viewFormAttributeBasedonFormUniqueId,

  getFormFieldSDVData,
  getLogFormFieldSDVData,
  getSpecialFormFieldSDVData,
  getSpecialLogFormFieldSDVData,
  viewFormFieldAttributeBasedonFormUniqueId,

  getSDVStatistic,
  getSDVLogStatistic,
  getSpecialSDVStatistic,
  getSpecialLogSDVStatistic,
  getAllFormField,

  getStudySiteId,
  getStudySubjectId,
  getStudyVisitId,
  checkDataEntryStudyVisitId,
  getStudyVisitIdBasedOnAssignForm,
  assignFormInitiateVisits,
  unassignFormInitiateVisits
};

async function getStudyVisitsFormAttributeData(endPoint, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitsFormStatusAttributeData(endPoint, studyId, dataEntryMode, studyUniqueId, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${studyId}/${dataEntryMode}/${studyUniqueId}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyFormValidationData() {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyFormValidationData`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function deleteStudyVisitFormsData(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}deleteStudyVisitsFormsData/${id}`,
      headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateStudyVisitFormsMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}updateStudyVisitsFormsMeta`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
  } catch (error) {
    console.log(error);
  }
}

async function duplicateStudyVisitFormsMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}duplicateStudyVisitFormsMeta`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function duplicateAssignStudyVisitFormsMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}duplicateAssignStudyVisitFormsMeta`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function sendForReviewStudyVisitForm(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}sendForReviewStudyVisitForm`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitsFormData(studyId, visitId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitsFormData/${studyId}/${visitId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getQuerySummeryDataForAllForm(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getQuerySummeryDataForAllForm/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getManageQueryStatusDataForAllFormField(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getManageQueryStatusDataForAllFormField/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function viewFormFieldAttributeBasedonFormFieldId(formUniqueId, formFieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}viewFormFieldAttributeBasedonFormFieldId/${formUniqueId}/${formFieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function veiwTicketQueryReplyComments(studyMode, studyUniqueId, ticketId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}veiwTicketQueryReplyComments/${studyMode}/${studyUniqueId}/${ticketId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getFormSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getFormSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getLogFormSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getLogFormSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialFormSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialFormSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialLogFormSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialLogFormSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function viewFormAttributeBasedonFormUniqueId(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}viewFormAttributeBasedonFormUniqueId/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getFormFieldSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getFormFieldSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getLogFormFieldSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getLogFormFieldSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialFormFieldSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialFormFieldSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialLogFormFieldSDVData(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialLogFormFieldSDVData/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function viewFormFieldAttributeBasedonFormUniqueId(formUniqueId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}viewFormFieldAttributeBasedonFormUniqueId/${formUniqueId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSDVStatistic(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSDVStatistic/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSDVLogStatistic(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSDVLogStatistic/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialSDVStatistic(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialSDVStatistic/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSpecialLogSDVStatistic(StudyMode, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getSpecialLogSDVStatistic/${StudyMode}/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getAllFormField(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getAllFormField/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudySiteId(studyId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudySiteId/${studyId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudySubjectId(studyId, siteId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudySubjectId/${studyId}/${siteId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitId(studyId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitId/${studyId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitIdBasedOnAssignForm(studyId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitIdBasedOnAssignForm/${studyId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function checkDataEntryStudyVisitId(studyId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}checkDataEntryStudyVisitId/${studyId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function assignFormInitiateVisits(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}assignFormInitiateVisits`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
  } catch (error) {
    console.log(error);
  }
}

async function unassignFormInitiateVisits(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}unassignFormInitiateVisits`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
  } catch (error) {
    console.log(error);
  }
}