import React from "react";
import { Modal } from "react-bootstrap";
import { userServices } from "../../../Services/userServices";
import TwoFactorAuthByApps from "./TwoFactorAuthByApps";
import TwoFactorAuthByEmail from "./TwoFactorAuthByEmail";
const TwoFactorAuth = ({ SHOW, HIDE, TITLE, setAuthType, authType, authTypeData, status, appTwoFA, emailTwoFA, getTwoFactorAuthType }) => {

  const roleType = JSON.parse(localStorage.getItem("role"));

  const twoFactorAuthType = async () => {
    let userData = {}
    userData.authType = authType
    let data = await userServices.twoFactorAuthType(userData);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
    }
  }

  let twoFAData = emailTwoFA || appTwoFA
  return (
    <Modal show={SHOW} onHide={HIDE}>
      <Modal.Header closeButton>
        <Modal.Title>{TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!twoFAData &&
          <div className={authTypeData ? "mb-5" : ""}>
            <label className="small mb-1" for="inputCountry">
              Authentication Type
            </label>
            <select
              className="form-select countryoption w-100"
              id="autoSizingSelect"
              disabled={status || roleType === "user"}
              onChange={(e) => setAuthType(e.target.value)}
            >
              <option>Select Auth Type</option>
              <option selected={authType === "email"} value={"email"}>By Email</option>
              <option selected={authType === "apps"} value={"apps"}>By Apps</option>
            </select>
            {(!status && roleType !== "user") &&
            <div className="text-end">
              <button onClick={(e) => twoFactorAuthType()} className="mt-2 updateProfile" type="button">
                Update
              </button>
            </div>
            }
          </div>
        }

        {authTypeData === "apps" ?
            <TwoFactorAuthByApps
              appTwoFA={appTwoFA}
              getTwoFactorAuthType={getTwoFactorAuthType}
            />
          : authTypeData === "email" ?
              <TwoFactorAuthByEmail
                emailTwoFA={emailTwoFA}
                getTwoFactorAuthType={getTwoFactorAuthType}
              />
            : ""
        }
      </Modal.Body>
    </Modal>
  );
};

export default TwoFactorAuth;
