import React, { useEffect, useState } from "react";
import { clientsOnboardingService } from "../../../../Services/clientsOnboarding";
import AttributesClientTable from "./AttributesClientTable";
import { PenSquareIcon } from "../../../../Assets/Icons/GlobalIcons";
import ClientsDataListingTable from "./ClientsDataListingTable";
import SplitPane from "react-split-pane";
import { NoDataFoundTable, SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";

const InactiveClients = () => {
  const [clientLists, setClientLists] = useState([]);
  const [clientDetails, setclientDetails] = useState("");
  const [activeTabs, setActiveTabs] = useState("properties");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [viewStudyDetailsVisible, setViewStudyDetailsVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getClientData = async () => {
    setLoader(true);
    let data = await clientsOnboardingService.getClientData("inactive");
    setClientLists(data?.data);
    setLoader(false);
  };

  const clientDetail = async (e) => {
    let userData = await clientsOnboardingService.getSingleClientForDevops(e);
    setclientDetails(userData.data);
  };

  useEffect(() => {
    getClientData();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = clientLists?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(clientLists?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="horizontal"
        style={{
          height: "calc(100vh - 181px)",
        }}
        className="position-relative"
        defaultSize={viewStudyDetailsVisible ? "40%" : "100%"}
      >
        <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
          {loader ? (
            <SpinerDataLoader />
          ) : clientLists?.length ? (
            <ClientsDataListingTable
              access={"inactiveClients"}
              clientLists={clientLists}
              clientDetail={clientDetail}
              PenSquareIcon={PenSquareIcon}
              totalData={clientLists}
              startIndex={startIndex}
              totalPage={totalPage}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={result?.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
              setViewStudyDetailsVisible={setViewStudyDetailsVisible}
            />
          ) : (
            <NoDataFoundTable MSG="Inactive Client Not Found!" />
          )}
        </SplitPane>
        {clientLists?.length > 0 && viewStudyDetailsVisible && (
          <>
            <div
              className={`pt-1 bg-light ${
                isFullScreen && "full-screen-component"
              }`}
            >
              <div className="hstack paginate fixed_tab_button justify-content-between">
                <div className="hstack me-1 gap-1">
                  {["properties"].map((tab) => (
                    <button
                      key={tab}
                      className={`buttonForTabs border-0 ${
                        activeTabs === tab ? "activee" : ""
                      }`}
                      onClick={() => setActiveTabs(tab)}
                      title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </div>
                <div className="d-flex gap-1">
                  <div className="toolbar">
                    <i
                      className={`fa-solid me-2 ${
                        !isFullScreen ? "fa-maximize" : "fa-minimize"
                      }`}
                      title={!isFullScreen ? "Full Mode" : "Original Mode"}
                      onClick={toggleFullScreen}
                    ></i>
                  </div>
                </div>
              </div>
              {activeTabs === "properties" ? (
                <AttributesClientTable clientDetails={clientDetails} />
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </SplitPane>
    </>
  );
};

export default InactiveClients;
