import React from "react";
import {
  ClientsIcon,
  DropdownArrowRightIcon,
  UsersClientsIcon,
} from "../../../Assets/Icons/GlobalIcons";

const InvitationInbox = ({
  countOfUserInvitation,
  approvedUserInvitation,
  rejectedUserInvitation,
  pendingUserInvitation,
  countOfClientInvitation,
  approvedClientInvitation,
  pendingClientInvitation,
  rejectedClientInvitation,
  handleAddNewTab,
  checkActiveTab,
}) => {
  // const {
  //   addUserPendingInvitationTab,
  //   addUserApprovedInvitationTab,
  //   addUserRejectedInvitationTab,
  //   addClientPendingInvitationTab,
  //   addClientApprovedInvitationTab,
  //   addClientRejectInvitationTab,
  // } = UseAddTabs();
  return (
    <>
      <li className="hstack justify-content-between w-100">
        <a
          data-bs-toggle="collapse"
          href="#userInvite"
          aria-expanded="false"
          aria-controls="userInvite"
          onClick={(e) => countOfUserInvitation(e)}
          className="w-100"
        >
          <div className="hstack justify-content-between w-100 ps-3">
            <div className="hstack gap-2">
              <i className="fa-solid fa-folder users"></i>
              <p className="m-0">
                Users (
                {approvedUserInvitation +
                  pendingUserInvitation +
                  rejectedUserInvitation}
                )
              </p>
            </div>
            <DropdownArrowRightIcon />
          </div>
        </a>
      </li>

      <div className="collapse client-collapse" id="userInvite">
        <div className="card card-body">
          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("pendingUserInvitation") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Pending User Invitation",
                "fa-solid fa-user-gear",
                "pendingUserInvitation"
              );
            }}
          >
            <UsersClientsIcon Class="archievedusers" />
            Pending ({pendingUserInvitation})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("approvedUserInvitation") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Approve User Invitation",
                "fa-solid fa-user-gear",
                "approvedUserInvitation"
              );
            }}
          >
            <UsersClientsIcon Class="activeclient" />
            Approved ({approvedUserInvitation})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("rejectedUserInvitation") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Rejected User Invitation",
                "fa-solid fa-user-gear",
                "rejectedUserInvitation"
              );
            }}
          >
            <UsersClientsIcon Class="inactiveclient" />
            Rejected ({rejectedUserInvitation})
          </span>
        </div>
      </div>

      <li className="hstack justify-content-between w-100">
        <a
          data-bs-toggle="collapse"
          href="#clientInvite"
          aria-expanded="false"
          aria-controls="clientInvite"
          onClick={(e) => countOfClientInvitation(e)}
          className="w-100"
        >
          <div className="hstack justify-content-between w-100 ps-3">
            <div className="hstack gap-2">
              <i className="fa-solid fa-folder users"></i>
              <p className="m-0">
                Clients (
                {approvedClientInvitation +
                  pendingClientInvitation +
                  rejectedClientInvitation}
                )
              </p>
            </div>
            <DropdownArrowRightIcon />
          </div>
        </a>
      </li>

      <div className="collapse client-collapse" id="clientInvite">
        <div className="card card-body">
          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("pendingClientInvitation") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Pending Client Invitation",
                "fa-solid fa-user-gear",
                "pendingClientInvitation"
              );
            }}
          >
            <ClientsIcon Class="archievedusers" />
            Pending ({pendingClientInvitation})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("approvedClientInvitation") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Approve Client Invitation",
                "fa-solid fa-user-gear",
                "approvedClientInvitation"
              );
            }}
          >
            <ClientsIcon Class="activeclient" />
            Approved ({approvedClientInvitation})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("rejectedClientInvitation") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Rejected Client Invitation",
                "fa-solid fa-user-gear",
                "rejectedClientInvitation"
              );
            }}
          >
            <ClientsIcon Class="inactiveclient" />
            Rejected ({rejectedClientInvitation})
          </span>
        </div>
      </div>
    </>
  );
};

export default InvitationInbox;
