import React from "react";
import ReactApexChart from "react-apexcharts";

const GradientDonutAdminUserChart = ({usersGraph}) => {
  const options = {
    chart: {
      type: "donut",
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      position: "bottom",
    },
    title: {
      text: "User Data",
    },
    labels: ["Active", "Inactive", "Freeze", "Archived", "Onboarding"],
    colors: ["#103C5EB3", "#103C5EA6", "#103C5E80", "#103C5E73", "#1717d8"],
  };

  return (
    <div id="chart" 
     // className="chart_design_port"
    className="p-2 py-3"
    >
      <ReactApexChart
        options={options}
        series={usersGraph}
        type="donut"
        height={300}
      />
    </div>
  );
};

export default GradientDonutAdminUserChart;
