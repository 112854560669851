import React, { useState } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../RightClick";
import { FaCheckCircle, FaEdit, FaTrashAlt } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { etmfReferenceModalServices } from "../../../Services/studyServices/etmfReferenceModalServices";
import Swal from "sweetalert2";

const ETMFList = ({
  referenceModalList,
  referenceModal,
  setReferenceModal,
  setModalId,
  modalId,
  getReferenceModals,
  setModalName,
  checkActiveTab,
  handleAddNewTab,
}) => {
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const updataReferenceModal = (id, referenceMadal) => {
    setReferenceModal(referenceMadal);
    setModalId(id);
    handleShow3();
  };

  const updateReferenceModal = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Modal?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = modalId;
        userData.name = referenceModal;
        let data = await etmfReferenceModalServices.updateReferenceModal(
          userData
        );
        if (data?.statusCode === 200) {
          getReferenceModals();
          handleClose3();
        }
      }
    });
  };

  const updateStatusFolderAndModal = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update Status this Modal?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await etmfReferenceModalServices.updateStatusFolderAndModal(
          userData
        );
        if (data?.statusCode === 200) {
          getReferenceModals();
          handleClose3();
        }
      }
    });
  };

  const deleteReferenceModalData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Modal?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await etmfReferenceModalServices.deleteReferenceModalData(
          id
        );
        if (data?.statusCode === 200) {
          getReferenceModals();
        }
      }
    });
  };
  return (
    <>
      {referenceModalList?.map((item, index) => (
        <div key={index}>
          <ContextMenuTrigger id={`contextmenuReference_${index}`}>
            <div
              // onClick={() => {
              //   // addReferencemodal();
              // }}
              className={`CP ps-4 small py-1 ${
                checkActiveTab("referenceModal") && "selectedMenu"
              }`}
              onClick={() => {
                setModalId(item._id);
                setModalName(item.referenceModal);
                handleAddNewTab(
                  `${item?.referenceModal}`,
                  "fa-solid fa-folder",
                  "referenceModal"
                );
              }}
              title={item.referenceModal}
            >
              <div className="hstack gap-2 " style={{ fontSize: 13 }}>
                <i
                  className={`${
                    item.status === "inactive" && "text-muted"
                  } fa-solid ${
                    modalId === item._id ? "fa-folder-open" : "fa-folder"
                  } users`}
                ></i>
                <p
                  className="m-0 text-truncate"
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.referenceModal}
                </p>
              </div>
            </div>
          </ContextMenuTrigger>
          <RightClick
            ContextId={`contextmenuReference_${index}`}
            Menu1
            icons1={<FaEdit className="edit" />}
            MenuName1={"Update Modal"}
            className1={"text-white"}
            handleClick1={() =>
              updataReferenceModal(item?._id, item.referenceModal)
            }
            Menu2
            icons2={
              <FaCheckCircle
                className={item.status === "active" ? "delete" : "edit"}
              />
            }
            MenuName2={`Update in ${
              item.status === "active" ? "Inactive" : "Active"
            } Status`}
            className2={"text-white"}
            handleClick2={() =>
              updateStatusFolderAndModal(
                item?._id,
                item.status === "active" ? "inactive" : "active"
              )
            }
            Menu3
            icons3={<FaTrashAlt className="delete" />}
            MenuName3={"Delete Modal"}
            className3={"text-white"}
            handleClick3={() => deleteReferenceModalData(item?._id)}
          />
        </div>
      ))}

      {/* Update Folder */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Update Reference Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column w-100">
            <input
              type="text"
              placeholder="Enter Reference Modal"
              className="form-control inputborder inputsize w-100 textCapitialize"
              onChange={(e) => setReferenceModal(e.target.value)}
              value={referenceModal}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <button
            className="border bg-light px-4 py-1 rounded-1 fw-bold"
            onClick={handleClose3}
          >
            Cancel
          </button>
          <button
            className="submitbutton px-4"
            onClick={updateReferenceModal}
            disabled={!referenceModal}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ETMFList;
