import React from "react";
import { MagnifierIcon } from "../../Assets/Icons/GlobalIcons";

const Searchbar = () => (
  <>
    <span className="d-flex ps-1">
      <MagnifierIcon />

      <select className="searchselector">
        <option value="" className="searchselectoption">
          Option1
        </option>

        <option value="" className="searchselectoption">
          Option2
        </option>

        <option value="" className="searchselectoption">
          Option3
        </option>
      </select>
    </span>

    <span>
      <input
        className="navsearch border-0"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
    </span>
  </>
);
export default Searchbar;
