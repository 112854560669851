import React from "react";
import { Button } from "react-bootstrap";

const SignOut = ({ logout }) => (
  <Button
  onClick={logout}
  variant="outline-danger"
  className="p-1 px-2 border-0 rounded-1"
  style={{ fontSize: 13 }}
  title="Sign out from panel"
>
  <i class="fa-solid fa-right-from-bracket"></i>Signout
</Button>
);

export default SignOut;
