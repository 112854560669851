import React, { useState } from "react";
import { Collapse, Form, ListGroup } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableDropdownTab from "./DraggableDropdownTab";

const TabHistory = ({
  open,
  edcTabs,
  handleMoveTab,
  handleActiveTab,
  handleDeleteTab,
  handleDeleteAllTab,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEDCTabs = edcTabs?.filter((edcTab) =>
    edcTab?.edcTabName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  return (
    <>
      <div className="position-absolute" style={{zIndex: 999, left: 8, top: 31 }}>
        <div
          className="w-100 shadow fs-13"
          style={{ borderRadius: "0px 0px 3px 3px" }}
        >
          {edcTabs?.length > 0 && (
            <Collapse in={open}>
              <div>
                <ListGroup
                  style={{
                    width: 207,
                    maxHeight: 300,
                    borderRadius: "0px 0px 3px 3px",
                  }}
                  className="tablist overflow-auto position-relative blank"
                >
                  <DndProvider backend={HTML5Backend}>
                    <ListGroup style={{borderRadius: "0px"}}>
                      <ListGroup.Item className="py-1 px-2 ps-3 position-sticky top-0 z-2">
                        <div className="hstack">
                          <i className={`fa-solid fa-search text-muted me-1`}></i>
                          <Form.Control
                            type="search"
                            className="p-0 border-0 py-2"
                            placeholder="Search Tabs"
                            value={searchQuery}
                            onChange={handleSearch}
                          />
                        </div>
                      </ListGroup.Item>
                      {filteredEDCTabs?.map((item, index) => (
                        <DraggableDropdownTab
                          key={item?.tabId}
                          item={item}
                          index={index}
                          handleMoveTab={handleMoveTab}
                          handleActiveTab={handleActiveTab}
                          handleDeleteTab={handleDeleteTab}
                        />
                      ))}
                      {filteredEDCTabs?.length === 0 && (
                        <ListGroup.Item
                          action
                          className={`py-1 px-2 ps-3 activeMenu`}
                        >
                          <Form.Label className="m-0 hstack cursor-pointer text-truncate w-100">
                            <i
                              className={`fa-regular fa-face-frown text-muted me-2`}
                            ></i>
                            Tab not found
                          </Form.Label>
                        </ListGroup.Item>
                      )}
                      {filteredEDCTabs?.length > 0 && (
                        <ListGroup.Item
                          onClick={handleDeleteAllTab}
                          action
                          style={{fontSize: 11}}
                          className="py-1 px-2 ps-3 text-danger text-center bg-danger-subtle border-danger border-opacity-10 fw-bold position-sticky bottom-0 z-1"
                        >
                          Close All Tabs
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </DndProvider>
                </ListGroup>
              </div>
            </Collapse>
          )}
        </div>
      </div>
    </>
  );
};

export default TabHistory;
