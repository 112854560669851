import React from "react";
const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
let role = JSON.parse(localStorage.getItem("role"));
let previousRole = JSON.parse(localStorage.getItem("previousRole"));
let studyStatusData = JSON.parse(localStorage.getItem("studyStatusData"));
const Profilers1 = ({ projectID, clientID }) => (
  <div className="justify-content-between align-items-center list-unstyled me-2 d-sm-flex profilers1">
    <i className="fa-solid fa-user-tie live__mode__color" />
    <span className="text-white client1">{clientID}</span>

    <i className="fa-solid fa-folder-open live__mode__color" />
    <span className="projectid">{projectID}</span>

    {(role === "study" && previousRole === "user") ||
      previousRole === "client" ? (
      <>
        <i className={(StudyMode === "DBP MODE" || StudyMode === "DV Mode") ? "fa-solid fa-circle dbp__mode__color" : ""} />
        <i className={(StudyMode === "SDV Live Mode" || StudyMode === "Live Mode") ? "fa-solid fa-circle live__mode__color" : ""} />
        <i className={(StudyMode === "SDV QC Mode" || StudyMode === "QC Mode") ? "fa-solid fa-circle qc__mode__color" : ""} />
        <span className="projectid"> {StudyMode}</span>
      </>
    ) : (
      ""
    )}
    <i className="fa-solid fa-folder live__mode__color" />
    <span className="projectid capitalize">Study Status : {studyStatusData}</span>
  </div>
);

export default Profilers1;
