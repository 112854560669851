import React, { useEffect, useState } from "react";
import { userServices } from "../../../../Services/userServices";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import Pagination from "../../../Pagination/Pagination";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";

const FailedLoginHistory = ({ tableRef, onMouseDown }) => {
  const [logHistory, setLogHistory] = useState([]);
  const [loader, setLoader] = useState(false);

  const userId = JSON.parse(localStorage.getItem("uniqueData"));
  const getUserFailedLoginData = async () => {
    setLoader(true);
    let data = await userServices.getUserFailedLoginData(userId);
    if (data?.statusCode === 404) {
      setLogHistory([]);
      setLoader(false);
    } else {
      setLogHistory(data);
      setLoader(false);
    }
  };

  useEffect(() => {
    getUserFailedLoginData();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = logHistory?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(logHistory?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        className="position-relative"
        style={{ height: "calc(100vh - 180px)" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : logHistory?.length > 0 ? (
          <>
            <Table
              id="resizable-table"
              striped
              hover
              className="m-0  custom-table"
            >
              <thead className="thead-sticky">
                <tr>
                  <th className="text-center" style={{ width: "55px" }}>
                    S. No
                  </th>
                  <th>IP Address</th>
                  <th>Location</th>
                  <th>TimeZone</th>
                  <th>UserId</th>
                  <th>Email</th>
                  <th>Password</th>
                  <th>Try Date | Time</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, i) => (
                  <tr key={i}>
                    <td className="text-center">{startIndex + i + 1}</td>
                    <td>{item.ipAddress}</td>
                    <td>{item.location}</td>
                    <td>{item.timeZone}</td>
                    <td>{item.userId}</td>
                    <td>{item.email}</td>
                    <td>{item.password}</td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {item.createdAt}
                      </Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={logHistory?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </>
        ) : (
          <NoDataFoundTable MSG="Failed Login Data Not Found!" />
        )}
      </SplitPane>
    </>
  );
};

export default FailedLoginHistory;
