import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";
import "../../Styles/SidebarDropdownStyle.scss";

const SidebarDropdowns = ({
  RemoveDropdowns,
  Dropdown1,
  Dropdown2,
  Dropdown3,
  Dropdown4,
  Dropdown5,
  Dropdown6,
  Dropdown7,
  Dropdown8,
}) => {
  const {
    addDrop1,
    addDrop2,
    addDrop3,
    addDrop4,
    addDrop5,
    addDrop6,
    addDrop7,
    addDrop8,
    removeDrop1,
    removeDrop2,
    removeDrop3,
    removeDrop4,
    removeDrop5,
    removeDrop6,
    removeDrop7,
    removeDrop8,
  } = UseSidebarReducer();

  const { Item } = Dropdown;
  const AppsRef = useRef(null);
  const [AppsActive, setAppsActive] = useState(false);
  const toggleApps = () => setAppsActive(!AppsActive);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleDropdownClick = (add, removes) => {
    toggleApps();
    add();
    removes.forEach(remove => remove());
  };

  return (
    <div ref={AppsRef} className={`Apps position-relative ${AppsActive ? "active" : ""}`}>
      <div className="icon_wrap hstack gap-1 p-1" onClick={toggleApps}>
        <div className={`title_name hstack gap-1 px-2 ${AppsActive ? "AppsNameActive" : ""}`} style={{ cursor: "pointer" }}>
          <div className="hstack gap-1">
          <i className="fa-solid fa-grip"></i>
            <p className="m-0">Apps</p>
          </div>
        </div>
      </div>
      <div className="Apps_dd shadow" style={{left: 5, top: 29, width: 300}}>
        <div className="d-flex w-100 border rounded-2">
          {RemoveDropdowns && (
            <ul className="Apps_ul border-left m-0 w-100 text-nowrap">
              <Form.Label className="m-0" style={{backgroundColor: "#dfdfdf"}}><i className="fa-solid fa-house-circle-check me-2"></i>General</Form.Label>
              {[Dropdown1, Dropdown2, Dropdown3, Dropdown4, Dropdown5].map((Dropdown, index) => (
                Dropdown && (
                  <li key={index}>
                    <Item onClick={() => {
                      handleDropdownClick(
                        [addDrop1, addDrop2, addDrop3, addDrop4, addDrop5][index],
                        [removeDrop1, removeDrop2, removeDrop3, removeDrop4, removeDrop5].filter((_, i) => i !== index)
                      ); 
                      removeDrop6()
                      removeDrop7()
                      removeDrop8()
                    }}>
                      <i className={`fa-solid ${['fa-users', 'fa-envelope', 'fa-users-gear', 'fa-building-lock', 'fa-clock-rotate-left'][index]} me-2`}></i> {Dropdown}
                    </Item>
                  </li>
                )
              ))}
            </ul>
          )}
          {RemoveDropdowns && (
            <ul className="Apps_ul border-left w-100 text-nowrap">
              <Form.Label className="m-0" style={{backgroundColor: "#dfdfdf"}}><i className="fa-brands fa-app-store me-2"></i>Apps</Form.Label>
              {[Dropdown6, Dropdown7, Dropdown8].map((Dropdown, index) => (
                Dropdown && (
                  <li key={index}>
                    <Item onClick={() => {
                      handleDropdownClick(
                        [addDrop6, addDrop7, addDrop8][index],
                        [removeDrop6, removeDrop7, removeDrop8].filter((_, i) => i !== index)
                      );
                      removeDrop1()
                      removeDrop2()
                      removeDrop3()
                      removeDrop4()
                      removeDrop5()
                    }}>
                      <i className={`fa-solid ${['fa-folder-open', 'fa-cubes', 'fa-shield'][index]} me-2`}></i> {Dropdown}
                    </Item>
                  </li>
                )
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarDropdowns;
