/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";
import { projectLevelFormServices } from "../Services/formServices/projectLevelFormServices";
import { clientSeparateLevelFormServices } from "../Services/formServices/clientSeparateLevelFormServices";
export const CrfViewContext = createContext();

export const CrfViewContextProvider = ({ children }) => {
  const [tableWidth, setTableWidth] = useState("");
  const [formUniqueId, setFormUniqueId] = useState("");
  const [studyUniqueId, setStudyUniqueId] = useState("")
  const [formType, setFormType] = useState("")
  const [visitFormType, setVisitFormType] = useState("")
  const [dataEntryFormMode, setDataEntryFormMode] = useState("")

  const [studyDynamicForm, setStudyDynamicForm] = useState([]);
  const [studyDynamicForm1, setStudyDynamicForm1] = useState([]);
  const [studyDynamicForm2, setStudyDynamicForm2] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);

  const [queryGenerateEndpoint, setQueryGenerateEndpoint] = useState("");
  const [queryCommentEndpoint, setQueryCommentEndpoint] = useState("");
  const [queryReplyEndpoint, setQueryReplyEndpoint] = useState("");
  const [getTicketsEndPoint, setGetTicketsEndPoint] = useState("");
  const [getQueryListEndPoint, setGetQueryListEndPoint] = useState("");
  const [reviewHistoryEndpoint, setReviewHistoryEndpoint] = useState("");
  const [formFieldHistoryEndpoint, setFormFieldHistoryEndpoint] = useState("");
  const [validationCheckListsEndpoint, setValidationCheckListsEndpoint] = useState("");
  const [dataEntryFormByIdEndPoint, setDataEntryFormByIdEndPoint] = useState("");
  const [dataEntryLockFreezeFormByIdEndPoint, setDataEntryLockFreezeFormByIdEndPoint] = useState("");
  const [dataEnteriesValueShow, setDataEnteriesValueShow] = useState("");
  const [reviewFormFieldEndpoint, setReviewFormFieldEndpoint] = useState("");
  const [updateTicketStatusEndPoint, setUpdateTicketStatusEndPoint] = useState("");
  const [getformFieldAttributes, setGetformFieldAttributes] = useState("");
  const [getGlobalQueryStatusEndPoint, setGetGlobalQueryStatusEndPoint] = useState("");
  // PROJECT LEVEL FORM.......................................................
  const [currentPage, setCurrentPage] = useState(1);

  const [count, setCount] = useState("");

  const limit = 100;

  const roleType = JSON.parse(localStorage.getItem("role"));

  let getProjectFormAPI = roleType === "client" ? projectLevelFormServices.getProjectLevelFormData : projectLevelFormServices.getProjectLevelFormForReviewer

  const getProjectLevelFormData = async (project_id) => {
    let data = await getProjectFormAPI(
      project_id,
      currentPage,
      limit
    );

    let finalData = data?.data?.sort().reverse();
    setStudyDynamicForm(finalData);
    setCount(Math.ceil(data?.count / limit));
  };

  let getClientFormAPI = roleType === "client" ? clientSeparateLevelFormServices.getClientSaperateLevelFormData : clientSeparateLevelFormServices.getClientSaperateLevelFormForReviewer

  const getClientSeparateLevelFormData = async (clientId) => {
    let data = await getClientFormAPI(
      clientId,
      currentPage,
      limit
    );

    let finalData = data?.data?.sort().reverse();
    setStudyDynamicForm2(finalData);
    setCount(Math.ceil(data?.count / limit));
    // setLoader(false);
  };

  // STUDY VISIT FORM............................................................

  const [studyVisitFormState, setStudyVisitFormState] = useState([]);
  const [studyVisitFormStateId, setStudyVisitFormStateId] = useState("");

  const [removeNevigation, setRemoveNevigation] = useState(false);

  const [fieldReviewStatus, setFieldReviewStatus] = useState(false);


  const contextValue = {

    formType,
    setFormType,
    studyUniqueId,
    setStudyUniqueId,
    formUniqueId,
    setFormUniqueId,
    studyDynamicForm,
    setStudyDynamicForm,
    studyDynamicForm1,
    setStudyDynamicForm1,
    studyDynamicForm2,
    setStudyDynamicForm2,
    selectedForm,
    setSelectedForm,
    queryGenerateEndpoint,
    setQueryGenerateEndpoint,
    getTicketsEndPoint,
    setGetTicketsEndPoint,
    getQueryListEndPoint,
    setGetQueryListEndPoint,
    reviewHistoryEndpoint,
    setReviewHistoryEndpoint,
    formFieldHistoryEndpoint,
    setFormFieldHistoryEndpoint,
    setValidationCheckListsEndpoint,
    validationCheckListsEndpoint,
    setDataEntryFormByIdEndPoint,
    setDataEntryLockFreezeFormByIdEndPoint,
    dataEntryLockFreezeFormByIdEndPoint,
    setDataEnteriesValueShow,
    dataEnteriesValueShow,
    dataEntryFormByIdEndPoint,
    reviewFormFieldEndpoint,
    setReviewFormFieldEndpoint,
    queryReplyEndpoint,
    setQueryReplyEndpoint,
    queryCommentEndpoint,
    setQueryCommentEndpoint,
    setGetformFieldAttributes,
    getformFieldAttributes,
    getGlobalQueryStatusEndPoint,
    setGetGlobalQueryStatusEndPoint,
    visitFormType,
    setVisitFormType,
    setDataEntryFormMode,
    dataEntryFormMode,

    currentPage,
    setCurrentPage,
    getProjectLevelFormData,
    getClientSeparateLevelFormData,
    count,
    setCount,
    updateTicketStatusEndPoint,
    setUpdateTicketStatusEndPoint,
    // .....................................

    studyVisitFormState,
    setStudyVisitFormState,
    studyVisitFormStateId,
    setStudyVisitFormStateId,
    setRemoveNevigation,
    removeNevigation,
    setFieldReviewStatus,
    fieldReviewStatus,
    tableWidth,
    setTableWidth,
    limit,
  };
  return (
    <CrfViewContext.Provider value={contextValue}>
      {children}
    </CrfViewContext.Provider>
  );
};

export const UseCrfViewContext = () => useContext(CrfViewContext);
