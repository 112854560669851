import { useEffect } from "react";
import { useContext } from "react";
import { createContext } from "react";

export const ResizeTableContext = createContext();
export const ResizablexContext = createContext();

export const AppProvider = ({ children }) => {
  // RESIZE TABLE..............................................................

  const resizeTable = () => {
    const createResizableColumn = function (col, resizer) {
      let x = 0;
      let w = 0;

      const mouseDownHandler = function (e) {
        x = e.clientX;

        const styles = window.getComputedStyle(col);
        w = parseInt(styles.width, 10);

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      const mouseMoveHandler = function (e) {
        const dx = e.clientX - x;
        col.style.width = `${w + dx}px`;
      };

      const mouseUpHandler = function () {
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };

      resizer.addEventListener("mousedown", mouseDownHandler);
    };

    const table = document.querySelector("#resizeMe");
    const table1 = document.querySelector("#resizeMe1");
    const table2 = document.querySelector("#resizeMe2");
    const table3 = document.querySelector("#resizeMe3");
    const table4 = document.querySelector("#resizeMe4");
    const table5 = document.querySelector("#resizeMe5");
    const table6 = document.querySelector("#resizeMe6");

    const resizeTable = (cols) => {
      [].forEach.call(cols, (col) => {
        const resizer = document.createElement("div");
        resizer.classList.add("resizer");
        resizer.style.height = `${table.offsetHeight}px`;
        col.appendChild(resizer);

        createResizableColumn(col, resizer);
      });
    };

    // FOR ONE TABLE............................................
    if (table && !table1 && !table2) {
      const cols = table.querySelectorAll("th");
      resizeTable(cols);
    }

    // FOR TWO TABLE............................................
    else if (table && table1 && !table2) {
      const cols = table.querySelectorAll("th");
      const cols1 = table1.querySelectorAll("th");
      resizeTable(cols);
      resizeTable(cols1);
    }

    // FOR THREE TABLE............................................
    else if (table && table1 && table2 && !table3) {
      const cols = table.querySelectorAll("th");
      const cols1 = table1.querySelectorAll("th");
      const cols2 = table2.querySelectorAll("th");
      resizeTable(cols);
      resizeTable(cols1);
      resizeTable(cols2);
    }

    // FOR THREE TABLE............................................
    else if (table && table1 && table2 && table3) {
      const cols = table.querySelectorAll("th");
      const cols1 = table1.querySelectorAll("th");
      const cols2 = table2.querySelectorAll("th");
      const cols3 = table3.querySelectorAll("th");
      resizeTable(cols);
      resizeTable(cols1);
      resizeTable(cols2);
      resizeTable(cols3);
    }

    // FOR FIVE TABLE............................................
    else if (table && table1 && table2 && table3 && table4) {
      const cols = table.querySelectorAll("th");
      const cols1 = table1.querySelectorAll("th");
      const cols2 = table2.querySelectorAll("th");
      const cols3 = table3.querySelectorAll("th");
      const cols4 = table4.querySelectorAll("th");
      resizeTable(cols);
      resizeTable(cols1);
      resizeTable(cols2);
      resizeTable(cols3);
      resizeTable(cols4);
    }

    // FOR SEVEN TABLE............................................
    else if (
      table &&
      table1 &&
      table2 &&
      table3 &&
      table4 &&
      table5 &&
      table6
    ) {
      const cols = table.querySelectorAll("th");
      const cols1 = table1.querySelectorAll("th");
      const cols2 = table2.querySelectorAll("th");
      const cols3 = table3.querySelectorAll("th");
      const cols4 = table4.querySelectorAll("th");
      const cols5 = table5.querySelectorAll("th");
      const cols6 = table6.querySelectorAll("th");
      resizeTable(cols);
      resizeTable(cols1);
      resizeTable(cols2);
      resizeTable(cols3);
      resizeTable(cols4);
      resizeTable(cols5);
      resizeTable(cols6);
    }
  };

  // RESIZE DASHBOARD BODY......................................................
  const resizableX = () => {
    // for mobile
    const ontouchstart = (e) => {
      e.preventDefault();
      resizer.addEventListener("touchmove", ontouchmove);
      resizer.addEventListener("touchend", ontouchend);
    };

    // for desktop
    const onmousedown = (e) => {
      e.preventDefault();
      document.addEventListener("mousemove", onmousemove);
      document.addEventListener("mouseup", onmouseup);
    };

    // const resizer = document.querySelector(".resizer-x");
    const resizer = document.querySelector("#resizer-x");
    resizer.addEventListener("mousedown", onmousedown);
    resizer.addEventListener("touchstart", ontouchstart);

    const resizeDashboard = (clientX) => {
      const deltaX = clientX - (resizer._clientX || clientX);
      resizer._clientX = clientX;
      const l = resizer.previousElementSibling;
      const r = resizer.nextElementSibling;

      // LEFT
      if (deltaX < 0) {
        const w = Math.round(parseInt(getComputedStyle(l).width) + deltaX);
        l.style.flex = `0 ${w < 10 ? 0 : w}px`;
        r.style.flex = "1 0";
      }

      // RIGHT
      if (deltaX > 0) {
        const w = Math.round(parseInt(getComputedStyle(r).width) - deltaX);
        r.style.flex = `0 ${w < 10 ? 0 : w}px`;
        l.style.flex = "1 0";
      }
    };

    const ontouchmove = (e) => {
      e.preventDefault();

      const clientX = e.touches[0].clientX;

      resizeDashboard(clientX);
    };

    const ontouchend = (e) => {
      e.preventDefault();
      resizer.removeEventListener("touchmove", ontouchmove);
      resizer.removeEventListener("touchend", ontouchend);
      delete e._clientX;
    };

    const onmousemove = (e) => {
      e.preventDefault();
      const clientX = e.clientX;
      resizeDashboard(clientX);
    };

    const onmouseup = (e) => {
      e.preventDefault();
      document.removeEventListener("mousemove", onmousemove);
      document.removeEventListener("mouseup", onmouseup);
      delete e._clientX;
    };
  };

  return (
    <ResizablexContext.Provider value={resizableX}>
      <ResizeTableContext.Provider value={resizeTable}>
        {children}
      </ResizeTableContext.Provider>
    </ResizablexContext.Provider>
  );
};

export const UseResizeTable = () => {
  const resizeTable = useContext(ResizeTableContext);
  useEffect(() => {
    resizeTable();
  });
};

export const UseResizeX = () => {
  const resizableX = useContext(ResizablexContext);
  useEffect(() => {
    resizableX();
  });
};