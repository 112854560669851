import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  ClientPermissionTab: false,
  addTabs: false,
  addTabs1: false,
  studyVisitsFormTab: false,
  newClientTab: false,
  newInvitationClientTab: false,
  newInvitationUserTab: false,
  clientTab: false,
  globalFormValidationStatus: false,
  globalFormCrfViewTab: false,
  globalFormCrfEditorTab: false,
  projectFormValidationStatus: false,
  activeDevopsUserTab: false,
  inactiveDevopsUserTab: false,
  archiveDevopsUserTab: false,
  freezeDevopsUserTab: false,
  onBoardingDevopsUserTab: false,
  studyFormValidationStatus: false,
  activeClientTab: false,
  studyDatabase: false,
  inactiveClientTab: false,
  referencemodal: false,
  archivedClientTab: false,
  freezedClientTab: false,
  clientCreationTab: false,
  clientApproveTab: false,
  userCreationTab: false,
  designationTab: false,
  userApproveTab: false,
  approvedUserTab: false,
  approvedClientTab: false,
  rejectedUserTab: false,
  rejectedClientTab: false,
  userPendingInvitationTab: false,
  userApprovedInvitationTab: false,
  userRejectedInvitationTab: false,
  clientPendingInvitationTab: false,
  clientApprovedInvitationTab: false,
  clientRejectInvitationTab: false,
  archivedPendingUserTab: false,
  logsHistoryData: false,
  failedLoginData : false,
  archivedApprovedUserTab: false,
  archivedRejectedUserTab: false,
  archivedPendingClientTab: false,
  archivedApprovedClientTab: false,
  archivedRejectedClientTab: false,
  editClientTab: false,
  asignStudyTab: false,
  studySummaryTab: false,
  requestTableTab: false,
  approveArchiveTab: false,
  protocolMetadataTab: false,
  labParametersTab: false,
  adverseEventTab: false,
  dispositionEventTab: false,
  studySiteTab: false,
  studyScheduleTab: false,
  studyVisitsTab: false,
  cDashTab: false,
  customTab: false,
  studyRandomisationTab: false,
  crfDatasets: false,
  genderDesigningTab: false,
  queryStatiscsDeaignTab: false,
  manageSDVManagementTab: false,
  sdvFieldDeaignTab: false,
  sdvStatiscsDeaignTab: false,
  keyMilestonesTab: false,
  monitoringVisitsTab: false,
  derivedVeriableTab: false,
  sampleReportPDFTab: false,
  subjectReportPDFTab: false,
  subjectVisitFormTab: false,
  subjectSpecialFormTab: false,
  subjectViewTab: false,
  manageQueryManagementTab: false,
  crfViewTab: false,
  visitsCrfViewTab: false,
  visitCrfTab: false,
  crfDesigningTab: false,
  demographic2Tab: false,
  dataEntrySiteTab: false,
  dataEntrySubjectTab: false,
  dataEntryVisitTab: false,
  dataEntryFormTab: false,
  dataTransferTableTab: false,
  dataExtractTab: false,
  compareDataTab: false,
  siteStatusTab: false,
  subjectStatusTab: false,
  subjectFormTab: false,
  subjectFormStatusTab: false,
  bulkUploadTab: false,
  queryManagementTab: false,
  querySummaryTab: false,
  discrepancyManagementTab: false,
  discrepancySummaryTab: false,
  keyMilestoneTab: false,
  monitoringVisitTab: false,
  submissionPlanTab: false,
  databaseLockTab: false,
  databaseApproveTab: false,
  userTab: false,
  inactiveUserTab: false,
  archivedUserTab: false,
  freezedUserTab: false,
  newUserTab: false,
  backupTab: false,
  clientBackupTab: false,
  userBackupTab: false,
  roleBackupTab: false,
  studyBackupTab: false,
  systemBackupTab: false,
  crfsBackupTab: false,
  studySchemaBackupTab: false,
  parameterBackupTab: false,
  termBackupTab: false,
  archiveTab: false,
  clientArchiveTab: false,
  studyArchiveTab: false,
  userArchiveTab: false,
  roleArchiveTab: false,
  crfsArchiveTab: false,
  studySchemaArchiveTab: false,
  parameterArchiveTab: false,
  termArchiveTab: false,
  programTab: false,
  cDiscSettingTab: false,
  studyDetailsTab: false,
  codeListTab: false,
  valueLevelTab: false,
  dataSettingTab: false,
  methodTab: false,
  commentTab: false,
  adverseEventCodingTab: false,
  accessTab: false,

  globalFormTab1: false,
  globalFormTab: false,
  viewStudyDetailsTableDataTab: false,
  clientLevelFormTab: false,
  projectLevelFormTab: false,
  clientSeparateLevelFormTab: false,
  QueryManagementtTab: false,
  QuerySummaryyTab: false,
  FormFieldMetadata: false,
  TrialCriteria: false,
  CustomEditCheck: false,
  AutoField: false,
  studyAccessTab: false,
  studySubjectSpecialFormFormTab: false,
  subjectMilestoneSettingScheduleTab: false,
  subjectVisitPlannedScheduleTab: false,
  controlQueryMessageTab: false,
  controlQueryResponseMessageTab : false,
  defaultTab: true,
};

export const customReducer = createReducer(initialState, {
  defaultTab: (state) => {
    state.defaultTab = true;
  },

  removeDefaultTab: (state) => {
    state.defaultTab = false;
  },
  // ............

  addTab: (state) => {
    state.addTabs = true;
  },

  removeTab: (state) => {
    state.addTabs = false;
  },
  // ............

  addClientTab: (state) => {
    state.clientTab = true;
  },

  removeClientTab: (state) => {
    state.clientTab = false;
  },

  addClientPermissionTab: (state) => {
    state.ClientPermissionTab = true;
  },

  removeClientPermissionTab: (state) => {
    state.ClientPermissionTab = false;
  },
  // .............

  addCrfViewTab: (state) => {
    state.crfViewTab = true;
  },

  removeCrfViewTab: (state) => {
    state.crfViewTab = false;
  },
  // .............

  addVisitsCrfViewTab: (state) => {
    state.visitsCrfViewTab = true;
  },

  removeVisitsCrfViewTab: (state) => {
    state.visitsCrfViewTab = false;
  },
  //--------
  addVerifyStudyMetaData: (state) => {
    state.VerifyStudyMetaData = true;
  },

  removeVerifyStudyMetaData: (state) => {
    state.VerifyStudyMetaData = false;
  },
  // .............

  addGlobalFormValidationStatus: (state) => {
    state.globalFormValidationStatus = true;
  },

  removeGlobalFormValidationStatus: (state) => {
    state.globalFormValidationStatus = false;
  },


  addGlobalFormCrfViewTab: (state) => {
    state.globalFormCrfViewTab = true;
  },

  removeGlobalFormCrfViewTab: (state) => {
    state.globalFormCrfViewTab = false;
  },


  addGlobalFormCrfEditorTab: (state) => {
    state.globalFormCrfEditorTab = true;
  },

  removeGlobalFormCrfEditorTab: (state) => {
    state.globalFormCrfEditorTab = false;
  },

  addProjectFormValidationStatus: (state) => {
    state.projectFormValidationStatus = true;
  },

  removeProjectFormValidationStatus: (state) => {
    state.projectFormValidationStatus = false;
  },

  addStudyFormValidationStatus: (state) => {
    state.studyFormValidationStatus = true;
  },

  removeStudyFormValidationStatus: (state) => {
    state.studyFormValidationStatus = false;
  },

  addStudyVisitsFormTab: (state) => {
    state.studyVisitsFormTab = true;
  },

  removeStudyVisitsFormTab: (state) => {
    state.studyVisitsFormTab = false;
  },
  // .............

  addInactiveClientTab: (state) => {
    state.inactiveClientTab = true;
  },

  removeInactiveClientTab: (state) => {
    state.inactiveClientTab = false;
  },
  // .............

  addReferencemodal: (state) => {
    state.referencemodal = true;
  },

  removeReferencemodal: (state) => {
    state.referencemodal = false;
  },
  // .............

  addArchivedClientTab: (state) => {
    state.archivedClientTab = true;
  },

  removeArchivedClientTab: (state) => {
    state.archivedClientTab = false;
  },
  // .............

  addFreezedClientTab: (state) => {
    state.freezedClientTab = true;
  },

  removeFreezedClientTab: (state) => {
    state.freezedClientTab = false;
  },

  // .............

  addOnBoardingClientTab: (state) => {
    state.onBoardingClientTab = true;
  },

  removeOnBoardingClientTab: (state) => {
    state.onBoardingClientTab = false;
  },

  // .............

  addStudyAaccessTab: (state) => {
    state.studyAccessTab = true;
  },

  removeStudyAccessTab: (state) => {
    state.studyAccessTab = false;
  },

  // .............

  addAssignStudyPermissionTab: (state) => {
    state.AssignStudyPermissionTab = true;
  },

  removeAssignStudyPermissionTab: (state) => {
    state.AssignStudyPermissionTab = false;
  },

  // .............

  addClientCreationTab: (state) => {
    state.clientCreationTab = true;
  },

  removeClientCreationTab: (state) => {
    state.clientCreationTab = false;
  },
  // ..............

  addClientApproveTab: (state) => {
    state.clientApproveTab = true;
  },

  removeClientApproveTab: (state) => {
    state.clientApproveTab = false;
  },

  // ..............

  userRequestsTab: (state) => {
    state.userRequestTab = true;
  },

  removeUserRequestsTab: (state) => {
    state.userRequestTab = false;
  },

  // ..............

  clientRequestsTab: (state) => {
    state.clientRequestTab = true;
  },

  removeClientRequestsTab: (state) => {
    state.clientRequestTab = false;
  },

  // ..............

  addUserCreationTab: (state) => {
    state.userCreationTab = true;
  },

  removeUserCreationTab: (state) => {
    state.userCreationTab = false;
  },
  // ..............

  addUserApproveTab: (state) => {
    state.userApproveTab = true;
  },

  removeUserApproveTab: (state) => {
    state.userApproveTab = false;
  },
  // ..............

  addEditClientTab: (state) => {
    state.editClientTab = true;
  },

  removeEditClientTab: (state) => {
    state.editClientTab = false;
  },
  // ..............

  addAsignStudyTab: (state) => {
    state.asignStudyTab = true;
  },

  removeAsignStudyTab: (state) => {
    state.asignStudyTab = false;
  },
  // ..............

  addStudySummaryTab: (state) => {
    state.studySummaryTab = true;
  },

  removeStudySummaryTab: (state) => {
    state.studySummaryTab = false;
  },
  // ...............

  addRequestTableTab: (state) => {
    state.requestTableTab = true;
  },

  removeRequestTableTab: (state) => {
    state.requestTableTab = false;
  },
  // ...............

  addApproveArchiveTab: (state) => {
    state.approveArchiveTab = true;
  },

  removeApproveArchiveTab: (state) => {
    state.approveArchiveTab = false;
  },
  // ...............

  addProtocolMetadataTab: (state) => {
    state.protocolMetadataTab = true;
  },

  removeProtocolMetadataTab: (state) => {
    state.protocolMetadataTab = false;
  },
  // ...............

  addLabParametersTab: (state) => {
    state.labParametersTab = true;
  },

  removeLabParametersTab: (state) => {
    state.labParametersTab = false;
  },
  // ...............

  addAdverseEventTab: (state) => {
    state.adverseEventTab = true;
  },

  removeAdverseEventTab: (state) => {
    state.adverseEventTab = false;
  },
  // ...............

  addDispositionEventTab: (state) => {
    state.dispositionEventTab = true;
  },

  removeDispositionEventTab: (state) => {
    state.dispositionEventTab = false;
  },
  // ...............

  addStudySiteTab: (state) => {
    state.studySiteTab = true;
  },

  removeStudySiteTab: (state) => {
    state.studySiteTab = false;
  },
  // ...............

  addStudyScheduleTab: (state) => {
    state.studyScheduleTab = true;
  },
  // ...............

  addStudyVisitsTab: (state) => {
    state.studyVisitsTab = true;
  },

  removeStudyScheduleTab: (state) => {
    state.studyScheduleTab = false;
  },

  removeStudyVisitsTab: (state) => {
    state.studyVisitsTab = false;
  },
  // ...............

  addCDashTab: (state) => {
    state.cDashTab = true;
  },

  removeCDashTab: (state) => {
    state.cDashTab = false;
  },
  // ...............

  addCustomTab: (state) => {
    state.customTab = true;
  },

  removeCustomTab: (state) => {
    state.customTab = false;
  },
  // ...............

  addSubjectManagementScheduleTab: (state) => {
    state.subjectManagementScheduleTab = true;
  },

  removeSubjectManagementScheduleTab: (state) => {
    state.subjectManagementScheduleTab = false;
  },
  // ...............

  addStudyRandomisationTab: (state) => {
    state.studyRandomisationTab = true;
  },

  removeStudyRandomisationTab: (state) => {
    state.studyRandomisationTab = false;
  },
  // ...............

  addCRFDatasets: (state) => {
    state.crfDatasets = true;
  },

  removeCRFDatasets: (state) => {
    state.crfDatasets = false;
  },
  // ...............

  addControlGenderDesigningTab: (state) => {
    state.genderDesigningTab = true;
  },

  addManageQueryManagementTab: (state) => {
    state.manageQueryManagementTab = true;
  },

  addQueryStatiscsDeaignTab: (state) => {
    state.queryStatiscsDeaignTab = true;
  },

  addManageSDVManagementTab: (state) => {
    state.manageSDVManagementTab = true;
  },

  addSDVFieldDeaignTab: (state) => {
    state.sdvFieldDeaignTab = true;
  },

  addSDVStatiscsDeaignTab: (state) => {
    state.sdvStatiscsDeaignTab = true;
  },

  addKeyMilestonesTab: (state) => {
    state.keyMilestonesTab = true;
  },

  addMonitoringVisitsTab: (state) => {
    state.monitoringVisitsTab = true;
  },

  removeControlGenderDesigningTab: (state) => {
    state.genderDesigningTab = false;
  },

  removeManageQueryManagementTab: (state) => {
    state.manageQueryManagementTab = false;
  },

  removeQueryStatiscsDeaignTab: (state) => {
    state.queryStatiscsDeaignTab = false;
  },

  removeManageSDVManagementTab: (state) => {
    state.manageSDVManagementTab = false;
  },

  removeSDVFieldDeaignTab: (state) => {
    state.sdvFieldDeaignTab = false;
  },

  removeSDVStatiscsDeaignTab: (state) => {
    state.sdvStatiscsDeaignTab = false;
  },

  removeKeyMilestonesTab: (state) => {
    state.keyMilestonesTab = false;
  },

  removeMonitoringVisitsTab: (state) => {
    state.monitoringVisitsTab = false;
  },
  // ...............

  addControlAgeGroupDesigningTab: (state) => {
    state.ageGroupDesigningTab = true;
  },

  removeControlAgeGroupDesigningTab: (state) => {
    state.ageGroupDesigningTab = false;
  },
  // ...............

  addDerivedVeriableTab: (state) => {
    state.derivedVeriableTab = true;
  },

  removeDerivedVeriableTab: (state) => {
    state.derivedVeriableTab = false;
  },
  // ...............

  addSampleReportPDFTab: (state) => {
    state.sampleReportPDFTab = true;
  },

  removeSampleReportPDFTab: (state) => {
    state.sampleReportPDFTab = false;
  },
  // ...............

  addSubjectReportPDFTab: (state) => {
    state.subjectReportPDFTab = true;
  },

  removeSubjectReportPDFTab: (state) => {
    state.subjectReportPDFTab = false;
  },
  // ...............

  addSubjectVisitFormTab: (state) => {
    state.subjectVisitFormTab = true;
  },

  removeSubjectVisitFormTab: (state) => {
    state.subjectVisitFormTab = false;
  },
  // ...............

  addSubjectSpecialFormTab: (state) => {
    state.subjectSpecialFormTab = true;
  },

  removeSubjectSpecialFormTab: (state) => {
    state.subjectSpecialFormTab = false;
  },
  // ...............

  addSubjectViewTab: (state) => {
    state.subjectViewTab = true;
  },

  removeSubjectViewTab: (state) => {
    state.subjectViewTab = false;
  },

  // ...............

  addControlLabUnitsTabs: (state) => {
    state.labUnitsDesigningTab = true;
  },

  removeControlLabUnitsTabs: (state) => {
    state.labUnitsDesigningTab = false;
  },

  // ...............

  addVisitCrfTab: (state) => {
    state.visitCrfTab = true;
  },

  removeVisitCrfTab: (state) => {
    state.visitCrfTab = false;
  },

  // ...............

  addCrfDesigningTab: (state) => {
    state.crfDesigningTab = true;
  },

  removeCrfDesigningTab: (state) => {
    state.crfDesigningTab = false;
  },
  // ...............

  addDemographic2Tab: (state) => {
    state.demographic2Tab = true;
  },

  removeDemographic2Tab: (state) => {
    state.demographic2Tab = false;
  },
  // ...............

  addDataEntrySiteTab: (state) => {
    state.dataEntrySiteTab = true;
  },

  removeDataEntrySiteTab: (state) => {
    state.dataEntrySiteTab = false;
  },
  // ...............

  addDataEntrySubjectTab: (state) => {
    state.dataEntrySubjectTab = true;
  },

  removeDataEntrySubjectTab: (state) => {
    state.dataEntrySubjectTab = false;
  },
  // ...............

  addDataEntryVisitTab: (state) => {
    state.dataEntryVisitTab = true;
  },

  removeDataEntryVisitTab: (state) => {
    state.dataEntryVisitTab = false;
  },
  // ...............

  addDataEntryFormTab: (state) => {
    state.dataEntryFormTab = true;
  },

  removeDataEntryFormTab: (state) => {
    state.dataEntryFormTab = false;
  },
  // ...............

  addDataTransferTableTab: (state) => {
    state.dataTransferTableTab = true;
  },

  removeDataTransferTableTab: (state) => {
    state.dataTransferTableTab = false;
  },
  // ...............

  addDataExtractTab: (state) => {
    state.dataExtractTab = true;
  },

  removeDataExtractTab: (state) => {
    state.dataExtractTab = false;
  },
  // ...............

  addCompareDataTab: (state) => {
    state.compareDataTab = true;
  },

  removeCompareDataTab: (state) => {
    state.compareDataTab = false;
  },
  // ...............

  addSiteStatusTab: (state) => {
    state.siteStatusTab = true;
  },

  removeSiteStatusTab: (state) => {
    state.siteStatusTab = false;
  },
  // ...............

  addSubjectStatusTab: (state) => {
    state.subjectStatusTab = true;
  },

  removeSubjectStatusTab: (state) => {
    state.subjectStatusTab = false;
  },
  // ...............

  addSubjectFormTab: (state) => {
    state.subjectFormTab = true;
  },

  removeSubjectFormTab: (state) => {
    state.subjectFormTab = false;
  },
  // ...............

  addSubjectFormStatusTab: (state) => {
    state.subjectFormStatusTab = true;
  },

  removeSubjectFormStatusTab: (state) => {
    state.subjectFormStatusTab = false;
  },
  // ...............
  addStudyVisitStatusTab: (state) => {
    state.StudyVisitStatusTab = true;
  },

  removeStudyVisitStatusTab: (state) => {
    state.StudyVisitStatusTab = false;
  },
  // ...............

  addBulkUploadTab: (state) => {
    state.bulkUploadTab = true;
  },

  removeBulkUploadTab: (state) => {
    state.bulkUploadTab = false;
  },
  // ...............

  addQueryManagementTab: (state) => {
    state.queryManagementTab = true;
  },

  removeQueryManagementTab: (state) => {
    state.queryManagementTab = false;
  },
  // ...............

  addQuerySummaryTab: (state) => {
    state.querySummaryTab = true;
  },

  removeQuerySummaryTab: (state) => {
    state.querySummaryTab = false;
  },
  // ...............

  addDiscrepancyManagementTab: (state) => {
    state.discrepancyManagementTab = true;
  },

  removeDiscrepancyManagementTab: (state) => {
    state.discrepancyManagementTab = false;
  },
  // ...............

  addDiscrepancySummaryTab: (state) => {
    state.discrepancySummaryTab = true;
  },

  removeDiscrepancySummaryTab: (state) => {
    state.discrepancySummaryTab = false;
  },
  // ...............

  addKeyMilestoneTab: (state) => {
    state.keyMilestoneTab = true;
  },

  removeKeyMilestoneTab: (state) => {
    state.keyMilestoneTab = false;
  },
  // ...............

  addMonitoringVisitTab: (state) => {
    state.monitoringVisitTab = true;
  },

  removeMonitoringVisitTab: (state) => {
    state.monitoringVisitTab = false;
  },
  // ...............

  addSubmissionPlanTab: (state) => {
    state.submissionPlanTab = true;
  },

  removeSubmissionPlanTab: (state) => {
    state.submissionPlanTab = false;
  },
  // ...............

  addDatabaseLockTab: (state) => {
    state.databaseLockTab = true;
  },

  removeDatabaseLockTab: (state) => {
    state.databaseLockTab = false;
  },
  // ...............

  addDesignationTab: (state) => {
    state.designationTab = true;
  },

  removeDesignationTab: (state) => {
    state.designationTab = false;
  },
  // ...............

  addDatabaseApproveTab: (state) => {
    state.databaseApproveTab = true;
  },

  removeDatabaseApproveTab: (state) => {
    state.databaseApproveTab = false;
  },
  // ...............

  addUserTab: (state) => {
    state.userTab = true;
  },

  removeUserTab: (state) => {
    state.userTab = false;
  },
  // ...............

  addInactiveUserTab: (state) => {
    state.inactiveUserTab = true;
  },

  removeInactiveUserTab: (state) => {
    state.inactiveUserTab = false;
  },
  // ...............

  addActiveDevopsUserTab: (state) => {
    state.activeDevopsUserTab = true;
  },

  removeActiveDevopsUserTab: (state) => {
    state.activeDevopsUserTab = false;
  },
  // ...............

  addInactiveDevopsUserTab: (state) => {
    state.inactiveDevopsUserTab = true;
  },

  removeInactiveDevopsUserTab: (state) => {
    state.inactiveDevopsUserTab = false;
  },

   // ...............

   addArchiveDevopsUserTab: (state) => {
    state.archiveDevopsUserTab = true;
  },

  removeArchiveDevopsUserTab: (state) => {
    state.archiveDevopsUserTab = false;
  },

   // ...............

   addFreezeDevopsUserTab: (state) => {
    state.freezeDevopsUserTab = true;
  },

  removeFreezeDevopsUserTab: (state) => {
    state.freezeDevopsUserTab = false;
  },

  // ...............

  addOnBoardingDevopsUserTab: (state) => {
    state.onBoardingDevopsUserTab = true;
  },

  removeOnBoardingDevopsUserTab: (state) => {
    state.onBoardingDevopsUserTab = false;
  },
  // ...............

  addArchivedUserTab: (state) => {
    state.archivedUserTab = true;
  },

  removeArchivedUserTab: (state) => {
    state.archivedUserTab = false;
  },
  // ...............

  addFolderUniqueIdTab: (state) => {
    state.folderUniqueIdTab = true;
  },

  removeFolderUniqueIdTab: (state) => {
    state.folderUniqueIdTab = false;
  },
  // ...............

  addVisitFormCrfEditorTab: (state) => {
    state.visitFormCrfEditorTab = true;
  },

  removeVisitFormCrfEditorTab: (state) => {
    state.visitFormCrfEditorTab = false;
  },
  // ...............

  addStudyFormCrfEditorTab: (state) => {
    state.studyFormCrfEditorTab = true;
  },

  removeStudyFormCrfEditorTab: (state) => {
    state.studyFormCrfEditorTab = false;
  },
  // ...............

  addProjectFormCrfEditorTab: (state) => {
    state.projectFormCrfEditorTab = true;
  },

  removeProjectFormCrfEditorTab: (state) => {
    state.projectFormCrfEditorTab = false;
  },
  // ...............

  addStudySubjectSpecialFormFormTab: (state) => {
    state.studySubjectSpecialFormFormTab = true;
  },

  removeStudySubjectSpecialFormFormTab: (state) => {
    state.studySubjectSpecialFormFormTab = false;
  },
  // ...............

  addSubjectVisitPlannedScheduleTab: (state) => {
    state.subjectVisitPlannedScheduleTab = true;
  },

  removeSubjectVisitPlannedScheduleTab: (state) => {
    state.subjectVisitPlannedScheduleTab = false;
  },
  // ...............

  addControlQueryMessageTab: (state) => {
    state.controlQueryMessageTab = true;
  },

  removeControlQueryMessageTab: (state) => {
    state.controlQueryMessageTab = false;
  },
  // ...............

  addControlQueryResponseMessageTab: (state) => {
    state.controlQueryResponseMessageTab = true;
  },

  removeControlQueryResponseMessageTab: (state) => {
    state.controlQueryResponseMessageTab = false;
  },
  // ...............

  removeStudyDatabase: (state) => {
    state.subjectMilestoneSettingScheduleTab = true;
  },

  removeSubjectMilestoneSettingScheduleTab: (state) => {
    state.subjectMilestoneSettingScheduleTab = false;
  },
  // ...............

  addStudyDatabase: (state) => {
    state.studyDatabase = true;
  },

  // removeStudyDatabase: (state) => {
  //   state.studyDatabase = false;
  // },
  // ...............

  addClientFormCrfEditorTab: (state) => {
    state.clientFormCrfEditorTab = true;
  },

  removeClientFormCrfEditorTab: (state) => {
    state.clientFormCrfEditorTab = false;
  },
  // ...............

  addFreezedUserTab: (state) => {
    state.freezedUserTab = true;
  },

  removeFreezedUserTab: (state) => {
    state.freezedUserTab = false;
  },
  // ...............

  addOnBoardingUserTab: (state) => {
    state.onBoardingUserTab = true;
  },

  removeOnBoardingUserTab: (state) => {
    state.onBoardingUserTab = false;
  },
  // ...............

  addNewUserTab: (state) => {
    state.newUserTab = true;
  },

  removeNewUserTab: (state) => {
    state.newUserTab = false;
  },
  // ...............

  addProgramTab: (state) => {
    state.programTab = true;
  },

  removeProgramTab: (state) => {
    state.programTab = false;
  },
  // ...............

  addBackupTab: (state) => {
    state.backupTab = true;
  },

  removeBackupTab: (state) => {
    state.backupTab = false;
  },
  // ...............

  addClientBackupTab: (state) => {
    state.clientBackupTab = true;
  },

  removeClientBackupTab: (state) => {
    state.clientBackupTab = false;
  },
  // ...............

  addNewClientTab: (state) => {
    state.newClientTab = true;
  },

  removeNewClientTab: (state) => {
    state.newClientTab = false;
  },
  // ...............

  addNewInvitationClientTab: (state) => {
    state.newInvitationClientTab = true;
  },

  removeNewInvitationClientTab: (state) => {
    state.newInvitationClientTab = false;
  },
  // ...............

  addNewInvitationUserTab: (state) => {
    state.newInvitationUserTab = true;
  },

  removeNewInvitationUserTab: (state) => {
    state.newInvitationUserTab = false;
  },
  // ...............

  addUserBackupTab: (state) => {
    state.userBackupTab = true;
  },

  removeUserBackupTab: (state) => {
    state.userBackupTab = false;
  },
  // ...............

  addRoleBackupTab: (state) => {
    state.roleBackupTab = true;
  },

  removeRoleBackupTab: (state) => {
    state.roleBackupTab = false;
  },
  // ...............

  addStudyBackupTab: (state) => {
    state.studyBackupTab = true;
  },

  removeStudyBackupTab: (state) => {
    state.studyBackupTab = false;
  },
  // ...............

  addSystemBackupTab: (state) => {
    state.systemBackupTab = true;
  },

  removeSystemBackupTab: (state) => {
    state.systemBackupTab = false;
  },
  // ...............

  addCrfsBackupTab: (state) => {
    state.crfsBackupTab = true;
  },

  removeCrfsBackupTab: (state) => {
    state.crfsBackupTab = false;
  },
  // ...............

  addStudySchemaBackupTab: (state) => {
    state.studySchemaBackupTab = true;
  },

  removeStudySchemaBackupTab: (state) => {
    state.studySchemaBackupTab = false;
  },
  // ...............

  addParameterBackupTab: (state) => {
    state.parameterBackupTab = true;
  },

  removeParameterBackupTab: (state) => {
    state.parameterBackupTab = false;
  },
  // ...............

  addTermBackupTab: (state) => {
    state.termBackupTab = true;
  },

  removeTermBackupTab: (state) => {
    state.termBackupTab = false;
  },
  // ...............

  addArchiveTab: (state) => {
    state.archiveTab = true;
  },

  removeArchiveTab: (state) => {
    state.archiveTab = false;
  },
  // ...............

  addClientArchiveTab: (state) => {
    state.clientArchiveTab = true;
  },

  removeClientArchiveTab: (state) => {
    state.clientArchiveTab = false;
  },
  // ...............

  addStudyArchiveTab: (state) => {
    state.studyArchiveTab = true;
  },

  removeStudyArchiveTab: (state) => {
    state.studyArchiveTab = false;
  },
  // ...............

  addUserArchiveTab: (state) => {
    state.userArchiveTab = true;
  },

  removeUserArchiveTab: (state) => {
    state.userArchiveTab = false;
  },
  // ...............

  addRoleArchiveTab: (state) => {
    state.roleArchiveTab = true;
  },

  removeRoleArchiveTab: (state) => {
    state.roleArchiveTab = false;
  },
  // ...............

  addCrfsArchiveTab: (state) => {
    state.crfsArchiveTab = true;
  },

  removeCrfsArchiveTab: (state) => {
    state.crfsArchiveTab = false;
  },
  // ...............

  addStudySchemaArchiveTab: (state) => {
    state.studySchemaArchiveTab = true;
  },

  removeStudySchemaArchiveTab: (state) => {
    state.studySchemaArchiveTab = false;
  },
  // ...............

  addParameterArchiveTab: (state) => {
    state.parameterArchiveTab = true;
  },

  removeParameterArchiveTab: (state) => {
    state.parameterArchiveTab = false;
  },
  // ...............

  addTermArchiveTab: (state) => {
    state.termArchiveTab = true;
  },

  removeTermArchiveTab: (state) => {
    state.termArchiveTab = false;
  },
  // ...............

  addCDiscSettingTab: (state) => {
    state.cDiscSettingTab = true;
  },

  removeCDiscSettingTab: (state) => {
    state.cDiscSettingTab = false;
  },
  // ...............

  addStudyDetailsTab: (state) => {
    state.studyDetailsTab = true;
  },

  removeStudyDetailsTab: (state) => {
    state.studyDetailsTab = false;
  },
  // ...............

  addCodeListTab: (state) => {
    state.codeListTab = true;
  },

  removeCodeListTab: (state) => {
    state.codeListTab = false;
  },
  // ...............

  addValueLevelTab: (state) => {
    state.valueLevelTab = true;
  },

  removeValueLevelTab: (state) => {
    state.valueLevelTab = false;
  },
  // ...............

  addDataSettingTab: (state) => {
    state.dataSettingTab = true;
  },

  removeDataSettingTab: (state) => {
    state.dataSettingTab = false;
  },
  // ...............

  addMethodTab: (state) => {
    state.methodTab = true;
  },

  removeMethodTab: (state) => {
    state.methodTab = false;
  },
  // ...............

  addCommentTab: (state) => {
    state.commentTab = true;
  },

  removeCommentTab: (state) => {
    state.commentTab = false;
  },
  // ...............

  addAdverseEventCodingTab: (state) => {
    state.adverseEventCodingTab = true;
  },

  removeAdverseEventCodingTab: (state) => {
    state.adverseEventCodingTab = false;
  },
  // ...............

  addAccessTab: (state) => {
    state.accessTab = true;
  },

  removeAccessTab: (state) => {
    state.accessTab = false;
  },
  // ...............

  addApprovedUserTab: (state) => {
    state.approvedUserTab = true;
  },

  removeApprovedUserTab: (state) => {
    state.approvedUserTab = false;
  },
  // ...............

  addApprovedClientTab: (state) => {
    state.approvedClientTab = true;
  },

  removeApprovedClientTab: (state) => {
    state.approvedClientTab = false;
  },
  // ...............

  addRejectedUserTab: (state) => {
    state.rejectedUserTab = true;
  },

  removeRejectedUserTab: (state) => {
    state.rejectedUserTab = false;
  },
  // ...............

  addRejectedClientTab: (state) => {
    state.rejectedClientTab = true;
  },

  removeRejectedClientTab: (state) => {
    state.rejectedClientTab = false;
  },
  // ...............

  addUserPendingInvitationTab: (state) => {
    state.userPendingInvitationTab = true;
  },

  removeUserPendingInvitationTab: (state) => {
    state.userPendingInvitationTab = false;
  },
  // ...............

  addUserApprovedInvitationTab: (state) => {
    state.userApprovedInvitationTab = true;
  },

  removeUserApprovedInvitationTab: (state) => {
    state.userApprovedInvitationTab = false;
  },
  // ...............

  addUserRejectedInvitationTab: (state) => {
    state.userRejectedInvitationTab = true;
  },

  removeUserRejectedInvitationTab: (state) => {
    state.userRejectedInvitationTab = false;
  },
  // ...............

  addClientPendingInvitationTab: (state) => {
    state.clientPendingInvitationTab = true;
  },

  removeClientPendingInvitationTab: (state) => {
    state.clientPendingInvitationTab = false;
  },
  // ...............

  addClientApprovedInvitationTab: (state) => {
    state.clientApprovedInvitationTab = true;
  },

  removeClientApprovedInvitationTab: (state) => {
    state.clientApprovedInvitationTab = false;
  },
  // ...............

  addClientRejectInvitationTab: (state) => {
    state.clientRejectInvitationTab = true;
  },

  removeClientRejectInvitationTab: (state) => {
    state.clientRejectInvitationTab = false;
  },
  // ...............

  addArchivedPendingUserTab: (state) => {
    state.archivedPendingUserTab = true;
  },

  removeArchivedPendingUserTab: (state) => {
    state.archivedPendingUserTab = false;
  },
  // ...............

  addLogsHistoryData: (state) => {
    state.logsHistoryData = true;
  },

  removeLogsHistoryData: (state) => {
    state.logsHistoryData = false;
  },
  // ...............

  addFailedLoginData: (state) => {
    state.failedLoginData = true;
  },

  removeFailedLoginData: (state) => {
    state.failedLoginData = false;
  },
  // ...............

  addArchivedApprovedUserTab: (state) => {
    state.archivedApprovedUserTab = true;
  },

  removeArchivedApprovedUserTab: (state) => {
    state.archivedApprovedUserTab = false;
  },
  // ...............

  addArchivedRejectedUserTab: (state) => {
    state.archivedRejectedUserTab = true;
  },

  removeArchivedRejectedUserTab: (state) => {
    state.archivedRejectedUserTab = false;
  },
  // ...............

  addArchivedPendingClientTab: (state) => {
    state.archivedPendingClientTab = true;
  },

  removeArchivedPendingClientTab: (state) => {
    state.archivedPendingClientTab = false;
  },
  // ...............

  addArchivedApprovedClientTab: (state) => {
    state.archivedApprovedClientTab = true;
  },

  removeArchivedApprovedClientTab: (state) => {
    state.archivedApprovedClientTab = false;
  },
  // ...............

  addArchivedRejectedClientTab: (state) => {
    state.archivedRejectedClientTab = true;
  },

  removearchivedRejectedClientTab: (state) => {
    state.archivedRejectedClientTab = false;
  },
  // ...............

  addGlobalFormTab: (state) => {
    state.globalFormTab = true;
  },

  removeGlobalFormTab: (state) => {
    state.globalFormTab = false;
  },
  // ...............

  addViewStudyTableDataTab: (state) => {
    state.viewStudyTableDataTab = true;
  },

  removeViewStudyTableDataTab: (state) => {
    state.viewStudyTableDataTab = false;
  },
  // ...............

  addViewStudyDetailsTableDataTab: (state) => {
    state.viewStudyDetailsTableDataTab = true;
  },

  removeViewStudyDetailsTableDataTab: (state) => {
    state.viewStudyDetailsTableDataTab = false;
  },
  // ...............

  addGlobalFormTab1: (state) => {
    state.globalFormTab1 = true;
  },

  removeGlobalFormTab1: (state) => {
    state.globalFormTab1 = false;
  },
  // ...............

  addClientLevelFormTab: (state) => {
    state.clientLevelFormTab = true;
  },

  removeClientLevelFormTab: (state) => {
    state.clientLevelFormTab = false;
  },
  // ...............

  addProjectLevelFormTab: (state) => {
    state.projectLevelFormTab = true;
  },
  // ...............

  removeProjectLevelFormTab: (state) => {
    state.projectLevelFormTab = false;
  },

  addClientSeparateLevelFormTab: (state) => {
    state.clientSeparateLevelFormTab = true;
  },

  removeClientSeparateLevelFormTab: (state) => {
    state.clientSeparateLevelFormTab = false;
  },
  // ...............

  addTab1: (state) => {
    state.addTabs1 = true;
  },
  // .............
  addQueryManagementtTab: (state) => {
    state.QueryManagementtTab = true;
  },

  removeQueryManagementtTab: (state) => {
    state.QueryManagementtTab = false;
  },
  // ...............


  addQuerySummaryyTab: (state) => {
    state.QuerySummaryyTab = true;
  },

  removeQuerySummaryyTab: (state) => {
    state.QuerySummaryyTab = false;
  },
  // ...............


  addFormFieldMetadataTab: (state) => {
    state.FormFieldMetadata = true;
  },

  removeFormFieldMetadataTab: (state) => {
    state.FormFieldMetadata = false;
  },
  // ...............


  addTrialCriteriaTab: (state) => {
    state.TrialCriteria = true;
  },

  removeTrialCriteriaTab: (state) => {
    state.TrialCriteria = false;
  },
  // ...............


  addCustomEditCheck: (state) => {
    state.CustomEditCheck = true;
  },

  removeCustomEditCheck: (state) => {
    state.CustomEditCheck = false;
  },
  // ...............

  addAutoFieldTab: (state) => {
    state.AutoField = true;
  },

  removeAutoFieldTab: (state) => {
    state.AutoField = false;
  },
  // ...............

});

