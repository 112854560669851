import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const clientSeparateLevelFormServices = {
  sendForReviewClientSeparateLevelForm,
  createClientLevelFormMeta,
  createClientSeparateLevelFormMeta,
  createForms,
  updateQuery,
  replyQuery,
  queryGenerator,
  getGlobalTickets,
  getQueryList,
  getFormFieldHistoryByID,
  getReviewHistoryByID,
  updateClientSeparateLevelFormsMeta,
  getClientSeparateLevelFormReviewedData,
  getClientSeparateLevelFormsHistoryByID,
  deleteClientSeparateLevelFormsData,
  getClientSaperateLevelFormData,
  getClientSaperateLevelFormForReviewer,
  getClientSeparateLevelFormAttributeData,
  exportClientSeparateLevelFormData,
  exportClientSeparateLevelFormAuditTrialDataData,
  


};

async function createClientLevelFormMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}createClientSeparateLevelFormMeta`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function createClientSeparateLevelFormMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}createClientSeparateLevelFormMeta`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function sendForReviewClientSeparateLevelForm(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}sendForReviewClientSeparateLevelForm`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function createForms(datas, endPoint) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateQuery(endPoint, datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function replyQuery(endPoint, datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function queryGenerator(endPoint, datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getGlobalTickets(endPoint, uniqueId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${uniqueId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}



async function getQueryList(endPoint, ticketId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${ticketId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getFormFieldHistoryByID(endPoint, formId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getReviewHistoryByID(endPoint, formId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getClientSaperateLevelFormData(clientId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getClientSaperateLevelFormData/${clientId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getClientSaperateLevelFormForReviewer(clientId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getClientSaperateLevelFormForReviewer/${clientId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getClientSeparateLevelFormReviewedData(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getClientSeparateLevelFormReviewedData/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateClientSeparateLevelFormsMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}updateClientSeparateLevelFormsMeta`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
  } catch (error) {
    console.log(error);
  }
}

async function getClientSeparateLevelFormsHistoryByID(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getClientSeparateLevelFormsHistoryByID/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function deleteClientSeparateLevelFormsData(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}deleteClientSeparateLevelFormsData/${id}`,
      headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function exportClientSeparateLevelFormData(studyId, extension) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}exportClientSeparateLevelFormData/${studyId}/${extension}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    let url = config.BACKEND_URL + data?.data;
    saveAs(url, data?.data);
  } catch (error) {
    console.log(error);
  }
}

async function exportClientSeparateLevelFormAuditTrialDataData(studyId, extension) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}exportClientSeparateLevelFormAuditTrialDataData/${studyId}/${extension}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    let url = config.BACKEND_URL + data?.data;
    saveAs(url, data?.data);
  } catch (error) {
    console.log(error);
  }
}

async function getClientSeparateLevelFormAttributeData(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getClientSeparateLevelFormAttributeData/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}
