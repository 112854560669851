import React from "react";
import Searchbar from "./Searchbar";
import SidebarDropdowns from "./SidebarDropdowns";
import SignOut from "./SignOut";

const BottomFilebar = ({
  logout,
  // Tools_Library,
  // Study_Tools,
  // FormDrop_GlobalForm,
  // FormDrop_ClientLevelForm,
  // FormDrop_ProjectLevelForm,
  RemoveDropdowns,
  Dropdown1,
  Dropdown2,
  Dropdown3,
  Dropdown4,
  Dropdown5,
  Dropdown6,
  Dropdown7,
  Dropdown8,
}) => {
  // const toolsProps = {
  //   Tools_Library,
  //   Study_Tools,
  //   FormDrop_GlobalForm,
  //   FormDrop_ClientLevelForm,
  //   FormDrop_ProjectLevelForm,
  // };

  const sidebarDropdownProps = {
    RemoveDropdowns,
    Dropdown1,
    Dropdown2,
    Dropdown3,
    Dropdown4,
    Dropdown5,
    Dropdown6,
    Dropdown7,
    Dropdown8,
  };

  return (
    <ul className="navbar-nav">
      <SidebarDropdowns {...sidebarDropdownProps} />

      {/* <li className="nav-item ms-0">
        <span className="nav-link active white CP">File</span>
      </li>

      <li className="nav-item">
        <span className="nav-link white CP">Edit</span>
      </li>

      <li className="nav-item">
        <span className="nav-link white CP">View</span>
      </li> */}

      {/* <li className="nav-item">
        <NavLink to="/userProfile" className="nav-link white CP">
          Profile
        </NavLink>
      </li> */}

      {/* {ToolsItem && (
        <li className="nav-item dropdown">
          <Dropdown>
            <Dropdown.Toggle variant="" className="nav-link">
              Tools
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Tools {...toolsProps} />
            </Dropdown.Menu>
          </Dropdown>
        </li>
      )} */}

      {/* <li className="nav-item">
        <span className="nav-link white CP">Help</span>
      </li>

      <li className="nav-item d-block d-lg-none">
        <span className="nav-link white CP">Notification</span>
      </li> */}

      <li className="nav-item d-block d-lg-none d-flex bg-white searchBar">
        <Searchbar />
      </li>

      <li className="nav-item d-block d-lg-none">
        <SignOut logout={logout} />
      </li>
    </ul>
  );
};

export default BottomFilebar;
