import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

const UseSidebarReducer = () => {
  const dispatch = useDispatch();

  const drop1State = useSelector(({ sidebarReducer: { drop1 } }) => drop1);
  const drop2State = useSelector(({ sidebarReducer: { drop2 } }) => drop2);
  const drop3State = useSelector(({ sidebarReducer: { drop3 } }) => drop3);
  const drop4State = useSelector(({ sidebarReducer: { drop4 } }) => drop4);
  const drop5State = useSelector(({ sidebarReducer: { drop5 } }) => drop5);
  const drop6State = useSelector(({ sidebarReducer: { drop6 } }) => drop6);
  const drop7State = useSelector(({ sidebarReducer: { drop7 } }) => drop7);
  const drop8State = useSelector(({ sidebarReducer: { drop8 } }) => drop8);

  const addDrop1 = () => {
    dispatch({
      type: "addDrop1",
    });
  };

  const addDrop2 = () => {
    dispatch({
      type: "addDrop2",
    });
  };

  const addDrop3 = () => {
    dispatch({
      type: "addDrop3",
    });
  };

  const addDrop4 = () => {
    dispatch({
      type: "addDrop4",
    });
  };

  const addDrop5 = () => {
    dispatch({
      type: "addDrop5",
    });
  };

  const addDrop6 = () => {
    dispatch({
      type: "addDrop6",
    });
  };

  const addDrop7 = () => {
    dispatch({
      type: "addDrop7",
    });
  };

  const addDrop8 = () => {
    dispatch({
      type: "addDrop8",
    });
  };
  // ........................................................

  const removeDrop1 = () => {
    dispatch({
      type: "removeDrop1",
    });
  };

  const removeDrop2 = () => {
    dispatch({
      type: "removeDrop2",
    });
  };

  const removeDrop3 = () => {
    dispatch({
      type: "removeDrop3",
    });
  };

  const removeDrop4 = () => {
    dispatch({
      type: "removeDrop4",
    });
  };

  const removeDrop5 = () => {
    dispatch({
      type: "removeDrop5",
    });
  };

  const removeDrop6 = () => {
    dispatch({
      type: "removeDrop6",
    });
  };

  const removeDrop7 = () => {
    dispatch({
      type: "removeDrop7",
    });
  };

  const removeDrop8 = () => {
    dispatch({
      type: "removeDrop8",
    });
  };

  const showSidebar = useMemo(
    () => drop1State || drop2State || drop3State || drop4State || drop5State || drop6State || drop7State || drop8State,
    [drop1State, drop2State, drop3State, drop4State, drop5State, drop6State, drop7State, drop8State], 
  );

  return {
    drop1State,
    drop2State,
    drop3State,
    drop4State,
    drop5State,
    drop6State,
    drop7State,
    drop8State,

    addDrop1,
    addDrop2,
    addDrop3,
    addDrop4,
    addDrop5,
    addDrop6,
    addDrop7,
    addDrop8,

    removeDrop1,
    removeDrop2,
    removeDrop3,
    removeDrop4,
    removeDrop5,
    removeDrop6,
    removeDrop7,
    removeDrop8,

    showSidebar,
  };
};

export default UseSidebarReducer;
