import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router";
import Devops from "./Pages/Roles/Devops/Devops";
import Loading from "./Components/Loading/Loading";
import { Permission } from "./ProtectRoute/permissionRoutes";
const AdminRole = lazy(() => import("./Pages/Roles/Admin/AdminRole"));
const UserRole = lazy(() => import("./Pages/Roles/UserRole/UserRole"));
const LoginPage = lazy(() => import("./Pages/Modules/Login/LoginPage"));
const ResetPassword = lazy(() => import("./Pages/Modules/Login/ResetPassword"));
const PageNotFound = lazy(() => import("./Components/PageNotFound/PageNotFound"));
const ForgotPassword = lazy(() => import("./Pages/Modules/Login/ForgotPassword"));
const ChangePassword = lazy(() => import("./Pages/Modules/UserProfile/changePassword"));

const App = () => {
  const {
    LoginPermission,
    AllPermission,
    AdminPermission,
    UserPermission,
  } = Permission;

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const table = tableRef.current;

        if (!table) {
          console.error('Table reference is null.');
          return;
        }

        const th = table.querySelector(`th:nth-child(${resizingColumn + 1})`);

        if (!th) {
          console.error(`No th element found for column index: ${resizingColumn}`);
          return;
        }

        th.style.width = `${width}px`;
        table.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef.current.querySelectorAll("th").forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<AllPermission />}>
            <Route path="/" element={<LoginPage />} />
          </Route>

          <Route path="/login" element={<LoginPermission />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>

          <Route path="/forgotPassword" element={<LoginPermission />}>
            <Route path="/forgotPassword" element={<ForgotPassword />} />
          </Route>

          <Route path="/resetPassword" element={<LoginPermission />}>
            <Route path="/resetPassword" element={<ResetPassword />} />
          </Route>

          <Route path="/changePassword" element={<AllPermission />}>
            <Route path="/changePassword" element={<ChangePassword />} />
          </Route>

          <Route path="/userRole" element={<UserPermission />}>
            <Route path="/userRole" element={<UserRole tableRef={tableRef} onMouseDown={onMouseDown} />} />
          </Route>

          {/* <Route path="/devops" element={<DevopsPermission />}> */}
            <Route path="/devops" element={<Devops tableRef={tableRef} onMouseDown={onMouseDown} />} />
          {/* </Route> */}

          <Route path="/adminRole" element={<AdminPermission />}>
            <Route path="/adminRole" element={<AdminRole tableRef={tableRef} onMouseDown={onMouseDown} />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />

        </Routes>
      </Suspense>
    </>
  );
};

export default App;
