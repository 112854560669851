import React from "react";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";

const ResizerXManager = () => {
  const { showSidebar } = UseSidebarReducer();

  const resizerXStyle = showSidebar ? "resizer-x" : " resizer-xHidden";

  return <div className={resizerXStyle} id="resizer-x" />;
};

export default ResizerXManager;
