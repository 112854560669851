import React from "react";
import { rolesType } from "../config/rolesType";
import { Outlet, Navigate } from "react-router";
import { getStore } from "../utils/universalFunction";
import Restriction from "../Components/Login/Restriction";
import ChangePassword from "../Pages/Modules/UserProfile/changePassword";
import TwoFactorAuth from "../Pages/Modules/Login/TwoFactorAuth";

const auth = getStore("token");
const role = JSON.parse(localStorage.getItem("role"));
const twoFA = JSON.parse(localStorage.getItem("twoFA"));
const updatePassword = JSON.parse(localStorage.getItem("updatePassword"));
const { admin, user, client, devops } = rolesType;

const AllPermission = () => {
  if (auth) {
    return twoFA ?
      <TwoFactorAuth /> :
      (!updatePassword ? (
        <ChangePassword />
      ) : updatePassword ? (
        <Outlet />
      ) : (
        <Restriction />
      ));
  } else {
    return <Navigate to="/login" />;
  }
};

const AdminPermission = () => {
  if (auth) {
    return twoFA ?
      <TwoFactorAuth /> :
      (!updatePassword ? (
        <ChangePassword />
      ) : admin === role ? (
        <Outlet />
      ) : (
        <Restriction />
      ));
  } else {
    return <Navigate to="/login" />;
  }
};

const UserPermission = () => {
  if (auth) {
    return twoFA ?
      <TwoFactorAuth /> :
      (!updatePassword ? (
        <ChangePassword />
      ) : user === role ? (
        <Outlet />
      ) : (
        <Restriction />
      ));
  } else {
    return <Navigate to="/login" />;
  }
};

const DevopsPermission = () => {
  if (auth) {
    return twoFA ?
      <TwoFactorAuth /> :
      (!updatePassword ? (
        <ChangePassword />
      ) : devops === role ? (
        <Outlet />
      ) : (
        <Restriction />
      ));
  } else {
    return <Navigate to="/login" />;
  }
};

const ClientPermission = () => {
  if (auth) {
    return twoFA ?
      <TwoFactorAuth /> :
      (!updatePassword ? (
        <ChangePassword />
      ) : client === role ? (
        <Outlet />
      ) : (
        <Restriction />
      ));
  } else {
    return <Navigate to="/login" />;
  }
};

const LoginPermission = () => {
  if (!auth) {
    return <Outlet to="/login" />;
  } else {
    return <Navigate to="/" />;
  }
};

export const Permission = {
  LoginPermission,
  AllPermission,
  AdminPermission,
  UserPermission,
  DevopsPermission,
  ClientPermission,
};
