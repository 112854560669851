import React from "react";
import EDCMainPanel from "../../../Components/EDC/EDCMainPanel";
import LogsHistoryData from "../../../Components/CommonComponents/DashboardBody/LogsData/LogsHistory";
import DevopsDashboard from "../../../Components/CommonComponents/DashboardBody/Dashboard/DevopsDashboard";
import ActiveClients from "../../../Components/CommonComponents/DashboardBody/DevopsRoleClients/ActiveClients";
import FreezeClients from "../../../Components/CommonComponents/DashboardBody/DevopsRoleClients/FreezeClients";
import FailedLoginHistory from "../../../Components/CommonComponents/DashboardBody/LogsData/FailedLoginHistory";
import OnboardClients from "../../../Components/CommonComponents/DashboardBody/DevopsRoleClients/OnboardClients";
import InactiveClients from "../../../Components/CommonComponents/DashboardBody/DevopsRoleClients/InactiveClients";
import ArchivedClients from "../../../Components/CommonComponents/DashboardBody/DevopsRoleClients/ArchivedClients";

const DevopsPage = ({
  edcTabs,
  setEDCTabs,
  activeEDCTab,
  checkActiveTab,
  checkHomeActiveTab,
}) => {
  return (
    <>
      <EDCMainPanel
        setEDCTabs={setEDCTabs}
        edcTabs={edcTabs}
        activeEDCTab={activeEDCTab}
      />

      {!checkHomeActiveTab() && <DevopsDashboard />}
      {checkActiveTab("activeClients") && <ActiveClients />}
      {checkActiveTab("inactiveClients") && <InactiveClients />}
      {checkActiveTab("archivedClients") && <ArchivedClients />}
      {checkActiveTab("frozenClients") && <FreezeClients />}
      {checkActiveTab("onBoardingClients") && <OnboardClients />}
      {checkActiveTab("logsHistory") && <LogsHistoryData />}
      {checkActiveTab("failedLogin") && <FailedLoginHistory />}
    </>
  );
};

export default DevopsPage;
