import React from "react";
import {
  ClientsIcon,
  DropdownArrowRightIcon,
  UsersClientsIcon,
} from "../../../Assets/Icons/GlobalIcons";

const ArchiveLists = ({
  stateChanger,
  pendingClients,
  approvedClients,
  rejectedClients,
  pendingUsers,
  approvedUsers,
  rejectedUsers,
  handleAddNewTab,
  checkActiveTab,
}) => {
  return (
    <>
      <li className="hstack justify-content-between w-100">
        <a
          data-bs-toggle="collapse"
          href="#archivedUserRequests"
          aria-expanded="false"
          aria-controls="archivedUserRequests"
          onClick={(e) => stateChanger(e)}
          className="w-100"
        >
          <div className="hstack justify-content-between w-100 ps-3">
            <div className="hstack gap-2">
              <i className="fa-solid fa-folder users"></i>
              <p className="m-0">
                Users ({pendingUsers + approvedUsers + rejectedUsers})
              </p>
            </div>
            <DropdownArrowRightIcon />
          </div>
        </a>
      </li>
      <div className="collapse client-collapse" id="archivedUserRequests">
        <div className="card card-body">
          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("archievedPendingUser") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Archived Pending User",
                "fa-solid fa-user-gear",
                "archievedPendingUser"
              );
            }}
          >
            <UsersClientsIcon Class="archievedusers" />
            Pending ({pendingUsers})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("archievedApprovedUser") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Archived Approved User",
                "fa-solid fa-user-gear",
                "archievedApprovedUser"
              );
            }}
          >
            <UsersClientsIcon Class="activeclient" />
            Approved ({approvedUsers})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("archievedRejectedUser") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Archived Rejected User",
                "fa-solid fa-user-gear",
                "archievedRejectedUser"
              );
            }}
          >
            <UsersClientsIcon Class="inactiveclient" />
            Rejected ({rejectedUsers})
          </span>
        </div>
      </div>

      <li className="hstack justify-content-between w-100">
        <a
          data-bs-toggle="collapse"
          href="#archivedClientsRequests"
          aria-expanded="false"
          aria-controls="archivedClientsRequests"
          onClick={(e) => stateChanger(e)}
          className="w-100"
        >
          <div className="hstack justify-content-between w-100 ps-3">
            <div className="hstack gap-2">
              <i className="fa-solid fa-folder users"></i>
              <p className="m-0">
                Clients ({pendingClients + approvedClients + rejectedClients})
              </p>
            </div>
            <DropdownArrowRightIcon />
          </div>
        </a>
      </li>

      <div className="collapse client-collapse" id="archivedClientsRequests">
        <div className="card card-body">
          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("archievedPendingClient") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Archived Pending Client",
                "fa-solid fa-user",
                "archievedPendingClient"
              );
            }}
          >
            <ClientsIcon Class="archievedusers" />
            Pending ({pendingClients})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("archievedApprovedClient") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Archived Approved Client",
                "fa-solid fa-user",
                "archievedApprovedClient"
              );
            }}
          >
            <ClientsIcon Class="activeclient" />
            Approved ({approvedClients})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("archievedRejectedClient") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Archived Rejected Client",
                "fa-solid fa-user",
                "archievedRejectedClient"
              );
            }}
          >
            <ClientsIcon Class="inactiveclient" />
            Rejected ({rejectedClients})
          </span>
        </div>
      </div>
    </>
  );
};

export default ArchiveLists;
