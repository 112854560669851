import { useState } from "react";
import { usersOnboardingService } from './../Services/usersOnboarding';

const UseAdminStates = () => {
  const [activeClients, setActiveClients] = useState([]);
  const [inactiveClients, setInactiveClients] = useState([]);
  const [freezedClients, setFreezedClients] = useState([]);
  const [onBoardingClients, setOnBoardingClients] = useState([]);
  const [archivedClients, setArchivedClients] = useState([]);
  const [approvedClients, setApprovedClients] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [freezedUsers, setFreezedUsers] = useState([]);
  const [onBoardingUsers, setOnBoardingUsers] = useState([]);
  const [archivedUsers, setArchivedUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);

  const [requestPendingClients, setRequestPendingClients] = useState([]);
  const [requestRejectedClients, setRequestRejectedClients] = useState([]);
  const [requestApprovedClients, setRequestApprovedClients] = useState([]);
  const [requestPendingUsers, setRequestPendingUsers] = useState([]);
  const [requestRejectedUsers, setRequestRejectedUsers] = useState([]);
  const [requestApprovedUsers, setRequestApprovedUsers] = useState([]);

  const [archivePendingClients, setArchivePendingClients] = useState([]);
  const [archiveRejectedClients, setArchiveRejectedClients] = useState([]);
  const [archiveApprovedClients, setArchiveApprovedClients] = useState([]);
  const [archivePendingUsers, setArchivePendingUsers] = useState([]);
  const [archiveRejectedUsers, setArchiveRejectedUsers] = useState([]);
  const [archiveApprovedUsers, setArchiveApprovedUsers] = useState([]);

  const childResponce = () => {
    countOfArchivedRequest();
  };

  const totalRequestCount = () => {
    countOfArchivedRequest();
    countOfRequestWithinDays();
  };

  const countOfRoleStatus = async (e) => {
    let count = await usersOnboardingService.countOfAllRoleStatus(e);
    let data = count.RoleStatus[0];
    setFreezedUsers(data.totalFreezeUsers > 0 ? data.totalFreezeUsers : 0);
    setOnBoardingUsers(data.totalOnBoardingUsers > 0 ? data.totalOnBoardingUsers : 0);
    setActiveUsers(data.totalActiveUsers > 0 ? data.totalActiveUsers : 0);
    setActiveClients(data.totalActiveClients > 0 ? data.totalActiveClients : 0);
    setArchivedUsers(data.totalArchiveUsers > 0 ? data.totalArchiveUsers : 0);
    setInactiveUsers(data.totalInactiveUsers > 0 ? data.totalInactiveUsers : 0);
    setFreezedClients(
      data.totalFreezeClients > 0 ? data.totalFreezeClients : 0
    );
    setOnBoardingClients(
      data.totalOnBoardingClients > 0 ? data.totalOnBoardingClients : 0
    );
    setInactiveClients(
      data.totalInactiveClients > 0 ? data.totalInactiveClients : 0
    );
    setArchivedClients(
      data.totalArchiveClients > 0 ? data.totalArchiveClients : 0
    );
    setApprovedClients(
      data.totalApprovedClients > 0 ? data.totalApprovedClients : 0
    );
    setApprovedUsers(data.totalApproveUsers > 0 ? data.totalApproveUsers : 0);
  };

  const countOfArchivedRequest = async (e) => {
    let count = await usersOnboardingService.countOfArchivedRequest(e);
    let data = count.RoleStatus[0];
    setArchivePendingClients(
      data.totalPendingClients > 0 ? data.totalPendingClients : 0
    );
    setArchiveApprovedClients(
      data.totalApprovedClients > 0 ? data.totalApprovedClients : 0
    );
    setArchiveRejectedClients(
      data.totalRejectedClients > 0 ? data.totalRejectedClients : 0
    );
    setArchivePendingUsers(
      data.totalPendingUsers > 0 ? data.totalPendingUsers : 0
    );
    setArchiveApprovedUsers(
      data.totalApproveUsers > 0 ? data.totalApproveUsers : 0
    );
    setArchiveRejectedUsers(
      data.totalRejectedUsers > 0 ? data.totalRejectedUsers : 0
    );
  };

  const countOfRequestWithinDays = async (e) => {
    let count = await usersOnboardingService.countOfRequestWithinDays(e);
    let data = count.RoleStatus[0];
    setRequestPendingClients(
      data.totalPendingClients > 0 ? data.totalPendingClients : 0
    );
    setRequestApprovedClients(
      data.totalApprovedClients > 0 ? data.totalApprovedClients : 0
    );
    setRequestRejectedClients(
      data.totalRejectedClients > 0 ? data.totalRejectedClients : 0
    );
    setRequestPendingUsers(
      data.totalPendingUsers > 0 ? data.totalPendingUsers : 0
    );
    setRequestApprovedUsers(
      data.totalApproveUsers > 0 ? data.totalApproveUsers : 0
    );
    setRequestRejectedUsers(
      data.totalRejectedUsers > 0 ? data.totalRejectedUsers : 0
    );
  };

  return {
    activeClients,
    setActiveClients,
    inactiveClients,
    setInactiveClients,
    freezedClients,
    setFreezedClients,
    onBoardingClients,
    setOnBoardingClients,
    archivedClients,
    setArchivedClients,
    approvedClients,
    setApprovedClients,
    activeUsers,
    setActiveUsers,
    inactiveUsers,
    setInactiveUsers,
    freezedUsers,
    setFreezedUsers,
    onBoardingUsers,
    setOnBoardingUsers,
    archivedUsers,
    setArchivedUsers,
    approvedUsers,
    setApprovedUsers,
    requestPendingClients,
    setRequestPendingClients,
    requestRejectedClients,
    setRequestRejectedClients,
    requestApprovedClients,
    setRequestApprovedClients,
    requestPendingUsers,
    setRequestPendingUsers,
    requestRejectedUsers,
    setRequestRejectedUsers,
    requestApprovedUsers,
    setRequestApprovedUsers,
    archivePendingClients,
    setArchivePendingClients,
    archiveRejectedClients,
    setArchiveRejectedClients,
    archiveApprovedClients,
    setArchiveApprovedClients,
    archivePendingUsers,
    setArchivePendingUsers,
    archiveRejectedUsers,
    setArchiveRejectedUsers,
    archiveApprovedUsers,
    setArchiveApprovedUsers,

    totalRequestCount,
    childResponce,
    countOfRoleStatus,
    countOfArchivedRequest,
    countOfRequestWithinDays,
  };
};

export default UseAdminStates;
