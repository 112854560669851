import React, { useEffect, useState } from 'react';
import QuickChart from 'quickchart-js';

const DoughnutChart = ({data, label, title, subjectLimit}) => {
  const [chartUrl, setChartUrl] = useState('');

  useEffect(() => {
    const generateChart = async () => {
      const chart = new QuickChart();

      chart.setWidth(300);
      chart.setHeight(200);
      chart.setVersion('2.9.4');

      chart.setConfig({
        type: 'doughnut',
        data: {
          labels: label || [],
          datasets: [
            {
              data: data || [],
              backgroundColor: ['#84add1', '#0177aa'],
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              display: true,
              backgroundColor: '#ccc',
              borderRadius: 3,
              font: {
                color: 'red',
                weight: 'bold',
              },
            },
            doughnutlabel: {
              labels: [
                {
                  text: subjectLimit,
                  font: {
                    size: 20,
                    weight: 'bold',
                  },
                },
                {
                  text: 'total',
                },
              ],
            },
          },
        },
      });

      const chartImageUrl = await chart.getShortUrl();
      setChartUrl(chartImageUrl);
    };

    generateChart();
  }, []);

  return (
    <div className='py-2'>
      <div className='text-center'><b>{title}</b></div>
      {chartUrl && <img src={chartUrl} alt="Chart" />}
    </div>
  );

}

export default DoughnutChart;
