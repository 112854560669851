import React from "react";
import ReactApexChart from "react-apexcharts";

const GradientDonutAdminInvitationChart = ({invitationGraph}) => {
  const options = {
    chart: {
      type: "donut",
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      position: "bottom",
    },
    title: {
      text: "Invitation",
    },
    labels: ["User Invitation", "Client Invitation"],
    colors: ["#103C5EB3", "#103C5EA6"],
  };

  return (
    <div id="chart" 
    // className="chart_design_port"
    className="p-2 py-3"
    >
      <ReactApexChart
        options={options}
        series={invitationGraph}
        type="donut"
        height={300}
      />
    </div>
  );
};

export default GradientDonutAdminInvitationChart;
