import React from "react";
import {
  ClientsIcon,
  UsersClientsIcon,
} from "../../../Assets/Icons/GlobalIcons";

const InboxLists = ({
  CountOfRequestWithinDays,
  RequestPendingUsers,
  RequestApprovedUsers,
  RequestRejectedUsers,
  RequestApprovedClients,
  RequestPendingClients,
  RequestRejectedClients,
  handleAddNewTab,
  checkActiveTab,
}) => {
  return (
    <>
      <li className="hstack justify-content-between w-100">
        <a
          data-bs-toggle="collapse"
          aria-expanded="true"
          aria-controls="userRequests"
          onClick={(e) => CountOfRequestWithinDays(e)}
          className="w-100"
        >
          <div className="d-flex justify-content-between w-100 px-1">
            <div className="hstack gap-2">
              <i className="fa-solid fa-folder users"></i>
              <p className="m-0">Users Request</p>
            </div>
          </div>
        </a>
      </li>
      <div className="collapse client-collapse show" id="userRequests">
        <div className="card card-body">
          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("pendingUserRequest") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Pending User Request",
                "fa-solid fa-user-gear",
                "pendingUserRequest"
              );
            }}
          >
            <UsersClientsIcon Class="archievedusers" />
            Pending ({RequestPendingUsers})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("approvedUserRequest") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Approved User Request",
                "fa-solid fa-user-gear",
                "approvedUserRequest"
              );
            }}
          >
            <UsersClientsIcon Class="activeclient" />
            Approved ({RequestApprovedUsers})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("rejectedUserRequest") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Rejected User Request",
                "fa-solid fa-user-gear",
                "rejectedUserRequest"
              );
            }}
          >
            <UsersClientsIcon Class="inactiveclient" />
            Rejected ({RequestRejectedUsers})
          </span>
        </div>
      </div>

      <li className="hstack justify-content-between w-100">
        <a
          data-bs-toggle="collapse"
          aria-expanded="true"
          aria-controls="clientRequests"
          onClick={(e) => CountOfRequestWithinDays(e)}
          className="w-100"
        >
          <div className="hstack justify-content-between w-100 px-1">
            <div className="hstack gap-2">
              <i className="fa-solid fa-folder users"></i>
              <p className="m-0">Clients Request</p>
            </div>
          </div>
        </a>
      </li>
      <div className="collapse client-collapse show" id="clientRequests">
        <div className="card card-body">
          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("pendingdClientRequest") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Pendingd Client Request",
                "fa-solid fa-user",
                "pendingdClientRequest"
              );
            }}
          >
            <ClientsIcon Class="archievedusers" />
            Pending ({RequestPendingClients})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("approvedClientRequest") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Approved Client Request",
                "fa-solid fa-user",
                "approvedClientRequest"
              );
            }}
          >
            <ClientsIcon Class="activeclient" />
            Approved ({RequestApprovedClients})
          </span>

          <span
            className={`CP ps-3 small ps-4 ${
              checkActiveTab("rejectedClientRequest") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Rejected Client Request",
                "fa-solid fa-user",
                "rejectedClientRequest"
              );
            }}
          >
            <ClientsIcon Class="inactiveclient" />
            Rejected ({RequestRejectedClients})
          </span>
        </div>
      </div>
    </>
  );
};

export default InboxLists;
